<template>
  <div>
    <b-button
        @click="$router.go(-1)"
        variant="outline-warning"
        class="btn-icon my-1"
        size="sm">
      <span class="">RETOUR</span>
    </b-button>
    <b-card>
      <div>
        <span class="h4">DETAILS DU VEHICULE</span>
        <div class="float-right">
          <span id="icon-synthese">
            <feather-icon
                v-if="$hasFonction(user, 'Voir véhicule')"
                class="iconFeather"
                icon="EyeIcon"
                size="20"
                @click="dialogSynthese = true"
            />
          </span>
          <b-tooltip
              target="icon-synthese"
              title="Synthèse"
              variant="dark"
          ></b-tooltip>

          <span id="icon-gallerie">
            <feather-icon
                v-if="$hasFonction(user, 'Modifier véhicule') && optionGallerie()"
                icon="CameraIcon"
                size="20"
                class="iconFeather ml-2 text-success"
                @click="dialogGalerie = true"
            />
          </span>
          <b-tooltip
              target="icon-gallerie"
              title="Gallerie"
              variant="dark"
          ></b-tooltip>

          <span id="icon-prix1">
            <feather-icon
                v-if="
                $hasFonction(user, 'Modifier véhicule') &&
                (vehicule.statut == 'en stock' || vehicule.statut == 'en dépôt')
              "
                icon="EditIcon"
                size="20"
                class="iconFeather ml-2"
                @click="updatePrice()"
            />
          </span>
          <b-tooltip
              target="icon-prix1"
              title="Modifier les prix"
              variant="dark"
          ></b-tooltip>

          <span id="icon-caution">
            <feather-icon
                v-if=" optionCaution() && $hasFonction(user, 'Paiement carte importateur vehicule')"
                icon="TrelloIcon"
                size="20"
                class="iconFeather ml-2 text-primary"
                @click="newCaution()"
            />
          </span>
          <b-tooltip
              target="icon-caution"
              title="Payer carte importateur"
              variant="dark"
          ></b-tooltip>

          <span id="icon-carte">
            <feather-icon
                v-if="
                optionPrintCI() &&
                $hasFonction(user, 'Impression carte importateur vehicule')
              "
                icon="FileIcon"
                size="20"
                class="iconFeather ml-2"
                @click="newCarte()"
            />
          </span>
          <b-tooltip
              target="icon-carte"
              title="Imprimer carte importateur"
              variant="dark"
          ></b-tooltip>

          <span id="icon-details">
            <feather-icon
                v-if="optionModifier() && $hasFonction(user, 'Modifier véhicule')"
                icon="FilePlusIcon"
                size="20"
                class="iconFeather ml-2"
                @click="editItem()"
            />
          </span>
          <b-tooltip
              target="icon-details"
              title="Détails"
              variant="dark"
          ></b-tooltip>

          <span id="icon-vendre">
            <feather-icon
                v-if="
                optionVendre() &&
                $hasFonction(user, 'Vendre véhicule / Recouvrement')
              "
                icon="ShoppingCartIcon"
                class="iconFeather text-success ml-2"
                size="20"
                @click="sellItem()"
            />
          </span>
          <b-tooltip
              target="icon-vendre"
              title="Vendre"
              variant="dark"
          ></b-tooltip>

          <span id="icon-proforma">
            <feather-icon
                v-if="
                optionVendre() &&
                $hasFonction(user, 'Vendre véhicule / Recouvrement')
              "
                icon="FileTextIcon"
                size="20"
                class="iconFeather ml-2"
                @click="newProforma()"
            />
          </span>
            <b-tooltip
              target="icon-perte"
              title="Perte"
              variant="dark"
          ></b-tooltip>

          <span id="icon-perte">
               <b-icon-graph-down
                   class="ml-2 text-primary"
                   style="font-size: 19px;"
                   @click="perte()"
                   v-if=" optionVendre()"
               ></b-icon-graph-down>
          </span>
          <b-tooltip
              target="icon-proforma"
              title="Proforma"
              variant="dark"
          ></b-tooltip>

          <span id="icon-client">
            <feather-icon
                v-if="
                optionFacture() &&
                $hasFonction(user, 'Modifier véhicule') &&
                $hasFonction(user, 'Voir facture')
              "
                icon="UsersIcon"
                size="20"
                class="iconFeather ml-2"
                @click="editClient()"
            />
          </span>
          <b-tooltip
              target="icon-client"
              title="Modifier le client"
              variant="dark"
          ></b-tooltip>

          <span id="icon-sortir">
            <feather-icon
                v-if="optionSortir() && $hasFonction(user, 'Sortir véhicule')"
                icon="ExternalLinkIcon"
                size="20"
                class="iconFeather text-success ml-2"
                @click="sortieVehicule()"
            />
          </span>
          <b-tooltip
              target="icon-sortir"
              title="SORTIR"
              variant="dark"
          ></b-tooltip>
          <!-- <b-button
            v-if="optionFacture() && $hasFonction(user, 'Voir facture')"
            size="sm"
            variant="outline-primary"
            class="ml-2"
            @click="seeFacture()"
          >
            <feather-icon icon="FileIcon" />
            <span style="margin-left: 10px">FACTURE</span>
          </b-button> -->

          <span id="icon-demarcheur">
            <feather-icon
                v-if="
                optionDemarcheur() && $hasFonction(user, 'Paiement démarcheur')
              "
                icon="UserIcon"
                size="20"
                class="iconFeather ml-2"
                @click="paidDemarcheur()"
            />
          </span>
          <b-tooltip
              target="icon-demarcheur"
              title="Payer démarcheur"
              variant="dark"
          ></b-tooltip>

          <span id="icon-recouvrir">
            <feather-icon
                v-if="
                optionRecouvrement() &&
                $hasFonction(user, 'Vendre véhicule / Recouvrement')
              "
                icon="ShoppingCartIcon"
                size="20"
                class="iconFeather text-success ml-2"
                @click="recouvrirVente()"
            />
          </span>
          <b-tooltip
              target="icon-recouvrir"
              title="Recouvrir"
              variant="dark"
          ></b-tooltip>

          <span id="icon-annulerVente">
            <feather-icon
                v-if="
                optionAnnulerVente() &&
                $hasFonction(user, 'Annuler vente véhicule')
              "
                icon="XOctagonIcon"
                size="20"
                class="iconFeather text-danger ml-2"
                @click="annulationVente()"
            />
          </span>
          <b-tooltip
              target="icon-annulerVente"
              title="Annuler la vente"
              variant="dark"
          ></b-tooltip>
        </div>
      </div>
    </b-card>
    <div>
      <b-row id="content-imgDetails">
        <div id="content-img" class="col-6">
          <b-carousel
              id="carousel-fade"
              style="text-shadow: 0px 0px 2px #000"
              fade
              controls
              indicators
          >
            <div v-if="gallerie && gallerie.length">
              <b-carousel-slide
                  style="height: 320px"
                  v-for="(slide, i) in gallerie"
                  :key="i"
                  :img-src="slide.url"
              ></b-carousel-slide>
            </div>

            <b-carousel-slide
                class="img-carousel"
                v-else
                style="height: 320px"
                :img-src="src"
            ></b-carousel-slide>
          </b-carousel>
        </div>

        <div id="content-details" class="col-6">
          <b-card no-body class="card-developer-meetup mb-0">
            <b-card-body
                id="nav-scroller"
                ref="content"
                style="position: relative; height: 320px; overflow-y: scroll"
            >
              <!-- metting header -->
              <div
                  class="
                  meetup-header
                  d-flex
                  align-items-center
                  pb-1
                  border-bottom
                "
              >
                <!-- <div class="meetup-day"> -->
                <!-- <b-avatar rounded variant="primary" size="40"> -->
                <!-- <feather-icon icon="TruckIcon" size="40" /> -->
                <!-- </b-avatar> -->
                <!-- </div> -->
                <div class="my-auto">
                  <b-card-title class="mb-25 text-uppercase">
                    Statut du véhicule
                  </b-card-title>
                  <b-card-text class="mb-0">
                    <!-- class="text-uppercase"
                      :variant="
                        vehicule.statut == 'en stock'
                          ? 'primary'
                          : vehicule.statut == 'en dépôt'
                          ? 'error'
                          : vehicule.statut == 'en attente'
                          ? 'warning'
                          : vehicule.statut == 'en cours de vente'
                          ? 'secondary'
                          : vehicule.statut == 'vendu'
                          ? 'primary'
                          : 'success'
                      " -->
                    <b-badge class="text-uppercase">
                      {{ vehicule.statut }}
                      {{
                        vehicule.statut == "livré"
                            ? "le " + vehicule.date_sortie
                            : ""
                      }}
                    </b-badge>
                  </b-card-text>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col border-right pr-0">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-calendar-event
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-calendar-event>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">DATE DE RECEPTION</h6>
                      <small>
                        {{
                          (vehicule&&vehicule.date_arrivee)
                              ? $formatDate(vehicule.date_arrivee)
                              : (vehicule&&vehicule.manifeste&&vehicule.manifeste.date_arrivee)
                                  ? vehicule.manifeste.date_arrivee
                                  : "----"
                        }}
                      </small>
                    </b-media-body>
                  </b-media>
                </div>
                <div class="col">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-columns-gap
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-columns-gap>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">MANIFESTE</h6>
                      <small>
                        <a class="text-uppercase" @click="goto(vehicule)">
                          <b-badge
                              style="background-color: #f90"
                              class="text-uppercase"
                          >
                            {{
                              vehicule.manifeste
                                  ? vehicule.manifeste.numero_manif
                                  : "----"
                            }}
                          </b-badge>
                        </a>
                      </small>
                    </b-media-body>
                  </b-media>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col border-right pr-0">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-collection
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-collection>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">ASSOCIATION</h6>
                      <small>
                        <a class="text-uppercase" @click="gotoa(vehicule.manifeste.association.id)">
                          <b-badge
                              style="background-color: #f90"
                              class="text-uppercase"
                          >
                            {{
                              vehicule.manifeste
                                  ? vehicule.manifeste.association.code
                                  : "----"
                            }}
                          </b-badge>
                        </a>
                      </small>
                    </b-media-body>
                  </b-media>
                </div>
                <div class="col">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-layers
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-layers>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">COULEUR</h6>
                      <small>
                        <a class="text-uppercase">
                          {{ vehicule.couleur ? vehicule.couleur : "----" }}
                        </a>
                      </small>
                    </b-media-body>
                  </b-media>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col border-right pr-0">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-dice1
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-dice1>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">CHASIS</h6>
                      <small>{{
                          vehicule.numero_chassis
                              ? vehicule.numero_chassis
                              : "----"
                        }}</small>
                    </b-media-body>
                  </b-media>
                </div>

                <div class="col">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-calendar-range
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-calendar-range>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">ANNEE</h6>
                      <small>
                        <a class="text-uppercase">
                          {{ vehicule.annee ? vehicule.annee : "----" }}
                        </a>
                      </small>
                    </b-media-body>
                  </b-media>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col border-right pr-0">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-capslock
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-capslock>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">PRIX DE REVIENT</h6>
                      <small
                      >{{
                          prix_revient_calcule
                              ? $thousandSeparator(prix_revient_calcule)
                              : "0"
                        }}
                        {{ vehicule.dev_conversion }}</small
                      >
                    </b-media-body>
                  </b-media>
                </div>

                <div class="col">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-cash-stack
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-cash-stack>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">{{ isBenOrNot }}</h6>
                      <small>
                        <a class="text-uppercase">
                          {{
                            benefice
                                ? $thousandSeparator(benefice)
                                : "Prix de vente non renseigné"
                          }}
                          {{ vehicule.dev_conversion }}
                        </a>
                      </small>
                    </b-media-body>
                  </b-media>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col border-right pr-0">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-alt
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-alt>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">CARBURANT</h6>
                      <small>{{
                          vehicule.carburant ? vehicule.carburant : "----"
                        }}</small>
                    </b-media-body>
                  </b-media>
                </div>

                <div class="col">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-door-open
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-door-open>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">NOMBRE DE PORTE</h6>
                      <small>{{
                          vehicule.nbr_portes ? vehicule.nbr_portes : "----"
                        }}</small>
                    </b-media-body>
                  </b-media>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col border-right pr-0">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-alarm
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-alarm>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">KILOMETRAGE</h6>
                      <small>{{
                          vehicule.kilometrage ? vehicule.kilometrage : "----"
                        }}</small>
                    </b-media-body>
                  </b-media>
                </div>

                <div class="col">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-compass
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-compass>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">BOITE DE VITESSE</h6>
                      <small>{{
                          vehicule.boite_vitesse ? vehicule.boite_vitesse : "----"
                        }}</small>
                    </b-media-body>
                  </b-media>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col border-right pr-0">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-award
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-award>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">CYLINDRE</h6>
                      <small>{{
                          vehicule.puissance_fiscale
                              ? vehicule.puissance_fiscale
                              : "----"
                        }}</small>
                    </b-media-body>
                  </b-media>
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-collection
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-collection>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">MARQUE</h6>
                      <small>{{
                          vehicule.marque ? vehicule.marque : "----"
                        }}</small>
                    </b-media-body>
                  </b-media>
                </div>

                <div class="col-12 mt-2">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-icon-bar-chart-steps
                          class="h3 text-primary"
                          style="margin-top: 5%; margin-right: 10px"
                      ></b-icon-bar-chart-steps>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="mb-0">CARACTERISTIQUES</h6>
                      <small>{{
                          vehicule.caracteristiques
                              ? vehicule.caracteristiques
                              : "----"
                        }}</small>
                    </b-media-body>
                  </b-media>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </b-row>
    </div>

    <div class="mt-2">
      <b-row>
        <b-col>
          <b-card class="mt-1 block-text" title="">
            <div class="mb-2">
              <span class="h4" style="margin-top: 5px">LISTE DES DÉPENSES</span>
              <div class="container-button float-right">
                <b-button
                    v-if="
                    optionDepense() && $hasFonction(user, 'Dépense véhicule')
                  "
                    size="sm"
                    variant="outline-primary"
                    class="mr-2"
                    @click="newDepense()"
                >
                  <feather-icon icon="ToolIcon" />
                  <span style="margin-left: 10px">DÉPENSE</span>
                </b-button>

                <b-button
                    v-if="$hasFonction(user, 'Dépense véhicule')"
                    size="sm"
                    variant="outline-primary"
                    class=""
                    @click="exportDepenses()"
                >
                  <feather-icon icon="EyeIcon" />
                  <span style="margin-left: 10px">EXPORTER</span>
                </b-button>

              </div>
            </div>
            <div class="mt-1">
              <b-table
                  id="bTable1"
                  responsive
                  :fields="headersDepenses"
                  :items="depenses"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  show-empty
                  class="border-bottom"
              >
                <template #cell(prix)="data">
                  {{ $thousandSeparator(data.item.prix) }}
                </template>

                <template #empty>
                  <div class="text-center py-2">
                    <span class="text-nowrap">Aucune donnée existante</span>
                  </div>
                </template>

                <template #emptyfiltered>
                  <div class="text-center py-2">
                    <span class="text-nowrap">
                      L'élément recherché n'est pas dans la liste
                    </span>
                  </div>
                </template>
              </b-table>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="mt-1 block-text" title="">
            <div class="mb-2">
              <span class="h4" style="margin-top: 5px">LISTE DES FACTURES</span>
              <div class="container-export-details-vehicule float-right">
                <b-button
                    v-if="$hasFonction(user, 'Voir facture')"
                    size="sm"
                    variant="outline-primary"
                    class=""
                    @click="exportData()"
                >
                  <feather-icon icon="EyeIcon" />
                  <span style="margin-left: 10px">EXPORTER</span>
                </b-button>
              </div>
            </div>
            <div class="mt-1">
              <b-table
                  id="bTable2"
                  responsive
                  :fields="headersFactures"
                  :items="factures"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  show-empty
                  class="border-bottom"
              >
                <template #cell(montant_vente)="data">
                  {{ $thousandSeparator(data.item.montant_vente) }}
                </template>

                <template #cell(montant_paye)="data">
                  {{ $thousandSeparator(data.item.montant_paye) }}
                </template>

                <template #cell(reste_a_paye)="data">
                  {{ $thousandSeparator(data.item.reste_a_paye) }}
                </template>

                <template #cell(actions)="data">
                  <div>
                    <b-button
                        :id="'button-1' + data.item.id"
                        v-if="$hasFonction(user, 'Voir facture')"
                        size="sm"
                        variant="flat-success"
                        class="btn-icon"
                        @click="showItem(data.item)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <!-- <b-tooltip
                      :target="'button-1' + data.item.id"
                      title="Détails"
                      variant="dark"
                    ></b-tooltip> -->
                  </div>
                </template>

                <template #empty>
                  <div class="text-center py-2">
                    <span class="text-nowrap">Aucune donnée existante</span>
                  </div>
                </template>

                <template #emptyfiltered>
                  <div class="text-center py-2">
                    <span class="text-nowrap">
                      L'élément recherché n'est pas dans la liste
                    </span>
                  </div>
                </template>
              </b-table>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- MODAUX -->

    <!-- Modal gallerie -->
    <b-modal
        size="lg"
        id="gallerie-modal"
        modal-class="modal-primary"
        v-model="dialogGalerie"
        title="Gallerie"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <div v-if="gallerie && gallerie.length">
        <div class="row">
          <div class="col-3" v-for="(slide, i) in gallerie" :key="i">
            <!-- <b-card no-body> -->
            <b-card no-body :img-src="slide.url" img-alt="Image" img-top>
              <b-card-header>
                <b-card-text class="font-medium-5 mb-0">
                  <feather-icon
                      @click="removeImage(slide)"
                      icon="XCircleIcon"
                      size="15"
                      class="cursor-pointer text-danger"
                  />
                </b-card-text>
              </b-card-header>
            </b-card>
          </div>
        </div>
      </div>

      <!-- form start -->
      <b-form class="mt-1" ref="printForm" @submit.stop.prevent="saveGallerie">
        <b-form-group>
          <validation-provider
              #default="{ errors }"
              name="images"
              rules="required"
          >
            <label for="images">Images</label>
            <b-form-file
                v-model="gallerieForm.images"
                multiple
                placeholder="Selectionner des images"
                accept=".jpg, .png"
            >
              <template slot="file-name" slot-scope="{ names }">
                <span v-for="(name, index) in names" :key="index">
                  <b-badge variant="dark">{{ name }}</b-badge> <br />
                </span>
              </template>
            </b-form-file>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogGalerie = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonGallerie"
              variant="primary"
              class="mt-2"
              @click="saveGallerie"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
      <!-- form end -->
    </b-modal>

    <!-- Modal des depenses -->
    <b-modal
        id="depense-modal"
        modal-class="modal-primary"
        v-model="dialogDepense"
        title="Effectuer une dépense"
        @hidden="resetDepense"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form class="mt-1" ref="printForm" @submit.stop.prevent="saveDepense">
        <b-form-group>
          <validation-provider
              #default="{ errors }"
              name="montant"
              rules="required"
          >
            <label for="montant">Montant de la dépense</label>
            <cleave
                class="form-control"
                :raw="false"
                id="bl"
                placeholder="0"
                :options="numberFormat"
                v-model="depenseForm.prix"
                :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
          >
            <label for="observation">Description</label>
            <b-form-input
                id="observation"
                type="text"
                v-model="depenseForm.observation"
                :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogDepense = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonDepense"
              variant="primary"
              class="mt-2"
              @click="saveDepense"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
      <!-- form end -->
    </b-modal>

    <!-- Modal de sortie -->
    <b-modal
        id="vente-modal"
        modal-class="modal-primary"
        v-model="dialogSortie"
        title="Sortir le vehicule"
        @hidden="resetSortie"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <b-form class="mt-1" ref="venteForm" @submit.stop.prevent="saveSortie">
        <b-row>
          <b-col>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
              >
                <label for="date">Date de sortie</label>
                <b-form-datepicker
                    v-model="sortieForm.date"
                    labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                    labelNoDateSelected="Aucune date sélectionnée"
                    labelResetButton="Annuler"
                    reset-button
                    :max="maxDate"
                    :state="errors.length > 0 ? false : null"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogSortie = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonSortie"
              variant="primary"
              class="mt-2"
              @click="saveSortie"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Modal recouvrement -->
    <b-modal
        size="lg"
        id="recouvrement-modal"
        modal-class="modal-primary"
        v-model="dialogRecouvrir"
        title="Recouvrir la vente"
        @hidden="resetRecouvrir"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <b-form
          class="mt-1"
          ref="venteForm"
          @submit.stop.prevent="saveRecouvrement"
      >
        <b-row>
          <b-col>
            <label for="">Reste à payer Initial</label>
            <b-button block variant="secondary">
              {{ $thousandSeparator(recouvrirForm.reste_a_paye) }}
            </b-button>
          </b-col>
          <b-col>
            <validation-provider
                #default="{ errors }"
                name="montant"
                rules="required"
            >
              <label for="">Montant à payer</label>
              <cleave
                  id="montant"
                  :raw="false"
                  :options="numberFormat"
                  class="form-control"
                  placeholder="0"
                  v-model="recouvrirForm.montant_paye"
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <label for="">Reste à payer final</label>
            <b-button block variant="secondary">
              {{ $thousandSeparator(recouvrirRapFinal) }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="checkDateLimiteRecouvrement">
          <b-col>
            <div class="demo-inline-spacing">
              <b-form-radio v-model="mode_solde" value="solderauplustard">
                Solde au plus tard
              </b-form-radio>
              <b-form-radio v-model="mode_solde" value="solderavantsortie">
                Solde av. sortie
              </b-form-radio>
            </div>
          </b-col>
          <b-col class="mt-1" v-if="existDateLimite">
            <b-form-input
                v-model="recouvrirForm.datelimite"
                type="date"
                class="d-inline-block"
            />
          </b-col>
        </b-row>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogRecouvrir = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonRecouvrement"
              variant="primary"
              class="mt-2"
              @click="saveRecouvrement"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Modal des ventes -->
    <b-modal
        size="lg"
        id="vente-modal"
        modal-class="modal-primary"
        v-model="dialogVente"
        title="Effectuer une vente"
        @hidden="resetVente"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form class="mt-1" ref="venteForm" @submit.stop.prevent="saveVente">
        <div>
          <form-wizard
              ref="form_wizard"
              :validateOnBack="true"
              back-button-text="Précédent"
              nextButtonText="Suivant"
              color="#27295c"
              :title="null"
              :subtitle="null"
              @tabIndexChanged="activeTabIndex"
              @on-complete="saveVente"
              class="steps-transparent"
          >
            <!-- client tab -->
            <tab-content title="Client" :before-change="validationFormClient">
              <validation-observer ref="clientRules" tag="form">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Client" label-for="clientName">
                      <validation-provider
                          #default="{ errors }"
                          name="clientName"
                      >
                        <!-- <b-form-select
                          id="clientName"
                          v-model="sellForm.client"
                          :options="clientsList"
                          value-field="id"
                          text-field="name"
                          @input="client_nameSelectSellReset"
                          :state="errors.length > 0 ? false : null"
                        /> -->
                        <Select2
                            id="clientNom"
                            :options="[].slice.call(clients).map(_c => { return {value: _c.id, text: _c.name} })"
                            dropDownParent="#vente-modal___BV_modal_content_"
                            lang="fr"
                            :change="checkClientData"
                        />
                        <!-- <b-form-input
                          list="clientsList"
                          id="clientName"
                          v-model="sellForm.client"
                          @change="client_nameSelectSellReset()"
                          :state="errors.length > 0 ? false : null"
                        />
                        <b-form-datalist id="clientsList" :options="clientsList"></b-form-datalist> -->
                        <small class="text-danger">{{
                            errors[0]
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col class="px-0 mt-2 col-2">
                    <b-button
                        variant="outline-dark"
                        size="md"
                        class=""
                        @click="modalAddClient = true"
                    >
                      Nouveau
                    </b-button>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                        label="Téléphone"
                        label-for="clientPhone"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="clientPhone"
                          rules=""
                      >
                        <b-form-input
                            id="clientPhone"
                            v-model="client_phone"
                            @keyup="checkClientDataPhone()"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Téléphone"
                        />
                        <small class="text-danger">{{
                            errors[0]
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Ifu" label-for="clientIfu">
                      <validation-provider
                          #default="{ errors }"
                          name="clientIfu"
                          rules=""
                      >
                        <b-form-input
                            id="clientIfu"
                            v-model="client_ifu"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Ifu"
                        />
                        <small class="text-danger">{{
                            errors[0]
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Reccm" label-for="clientReccm">
                      <validation-provider
                          #default="{ errors }"
                          name="clientReccm"
                          rules=""
                      >
                        <b-form-input
                            id="clientReccm"
                            v-model="client_reccm"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Reccm"
                        />
                        <small class="text-danger">{{
                            errors[0]
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- deposant tab -->
            <tab-content
                title="Déposant"
                :before-change="validationFormDeposant"
            >
              <validation-observer ref="deposantRules" tag="form">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nom & Prenom" label-for="deposantName">
                      <validation-provider
                          #default="{ errors }"
                          name="deposantName"
                          rules=""
                      >
                        <b-form-input
                            id="deposantName"
                            type="text"
                            v-model="venteForm.deposant_name"
                            placeholder="Nom & prenom"
                            :disabled="activeCheckBox == 1 ? false : true"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Téléphone" label-for="deposantPhone">
                      <validation-provider
                          #default="{ errors }"
                          name="deposantPhone"
                          rules=""
                      >
                        <b-form-input
                            id="deposantPhone"
                            type="number"
                            v-model="venteForm.deposant_phone"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Téléphone"
                            :disabled="activeCheckBox == 1 ? false : true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="mb-1">
                  <span>Existe t'il un déposant ? </span>
                  <b-form-checkbox
                      class="ml-2"
                      style="padding-top: 3px"
                      switch
                      inline
                      value="1"
                      unchecked-value="0"
                      name="check-button"
                      v-model="activeCheckBox"
                  >
                  </b-form-checkbox>
                </div>
              </validation-observer>
            </tab-content>

            <!-- signataire tab  -->
            <tab-content
                title="Signataire"
                :before-change="validationFormSignataire"
            >
              <validation-observer ref="signataireRules" tag="form">
                <b-row>
                  <b-col md="6">
                    <b-form-group class="col pr-0">
                      <validation-provider
                          #default="{ errors }"
                          name="signataireName"
                          rules="required"
                      >
                        <label for="signataireName">Nom signataire</label>
                        <b-form-select
                            id="signataire"
                            v-model="signataire"
                            :options="signataires"
                            value-field="name"
                            text-field="name"
                            @change="checkSignatairesData()"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!--                    <b-form-group
                                          label="Nom signataire"
                                          label-for="signataireName"
                                        >
                                          <validation-provider
                                            #default="{ errors }"
                                            name="signataireName"
                                            rules="required"
                                          >
                                            <b-form-select
                                                ref="signataires"
                                                v-model="venteForm.signataire"
                                                :options="signataires"
                                                value-field="name"
                                                text-field="name"
                                                @change="checkSignatairesData()"
                                                :state="errors.length > 0 ? false : null"
                                            >
                                            </b-form-select>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </b-form-group>-->
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Téléphone" label-for="signatairePhone">
                      <validation-provider
                          #default="{ errors }"
                          name="signatairePhone"
                          rules=""
                      >
                        <b-form-input
                            id="signatairePhone"
                            type="number"
                            value-field="name"
                            text-field="name"
                            @keyup="checkSignatairesDataPhone()"
                            v-model="signataire_phone"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Téléphone"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- details du paiement -->
            <tab-content
                class="pb-1"
                title="Paiement"
                :before-change="validationFormDetails"
            >
              <validation-observer ref="detailsRules" tag="form">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                        label="Type de facture"
                        label-for="typeFacture"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="typeFacture"
                          rules="required"
                      >
                        <b-form-select
                            id="typeFacture"
                            :disabled="Isimportateur===0"
                            v-model="venteForm.invoice_type"
                            :options="factureTypes"
                            value-field="value"
                            text-field="text"
                            :state="errors.length > 0 ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Type de vente" label-for="typeVente">
                      <validation-provider
                          #default="{ errors }"
                          name="typeVente"
                          rules=""
                      >
                        <b-form-select
                            :disabled="disableVenteTypes"
                            id="typeVente"
                            value-field="value"
                            text-field="text"
                            v-model="venteForm.venteType"
                            :options="venteTypes"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--                  <b-col
                                      md="6"
                                      v-if="venteForm.invoice_type == 'Facture simple' && Isimportateur===1"
                                    >
                                      <b-form-group
                                        label="Type de traitement"
                                        label-for="typeTraitement"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="typeTraitement"
                                          rules=""
                                        >
                                          <b-form-select
                                            id="typeTraitement"
                                            v-model="venteForm.traitement_special"
                                            :options="traiementTypes"
                                            value-field="value"
                                            text-field="text"
                                            :state="errors.length > 0 ? false : null"
                                          />
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>-->

                  <b-col md="6">
                    <b-form-group label="Prix de vente" label-for="prix_vente">
                      <validation-provider
                          #default="{ errors }"
                          name="prix de vente"
                          rules="required"
                      >
                        <cleave
                            class="form-control"
                            :raw="false"
                            id="prix_vente"
                            placeholder="0"
                            :options="numberFormat"
                            v-model="venteForm.prix_vente"
                            @change="checkPriceAlert()"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                        class="mt-10"
                        label="Montant payé"
                        label-for="montant_paye"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="montant_paye"
                          rules="required"
                      >
                        <cleave
                            class="form-control"
                            :raw="false"
                            id="prix_vente"
                            placeholder="0"
                            :options="numberFormat"
                            v-model="montant_paye"
                            @input="checkPrice()"
                            :state="errors.length > 0 ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                      :md="
                      venteForm.invoice_type == 'Facture normalisée' ? 12 : 6
                    "
                  >
                    <b-form-group label="Reste à payer" label-for="rap">
                      <b-button block variant="secondary">
                        {{ $thousandSeparator(rap) }}
                      </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="checkDateLimite">
                  <b-col>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                          v-model="mode_solde"
                          value="solderauplustard"
                      >
                        Solde au plus tard
                      </b-form-radio>
                      <b-form-radio
                          v-model="mode_solde"
                          value="solderavantsortie"
                      >
                        Solde av. sortie
                      </b-form-radio>
                    </div>
                  </b-col>
                  <b-col v-if="existDateLimite">
                    <b-form-input
                        v-model="venteForm.datelimite"
                        type="date"
                        class="d-inline-block"
                    />
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
          </form-wizard>
        </div>

        <b-form-group class="mb-0 mt-1">
          <!--          <b-button
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2 mr-1"
                      @click="dialogVente = false"
                    >
                      Annuler
                    </b-button>-->

          <b-button
              v-if="etatButtonSell"
              variant="primary"
              class="mt-2"
              :disabled="btnSave < 3"
              @click="saveVente"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Modal des proforma -->
    <b-modal
        size="lg"
        id="proforma-modal"
        modal-class="modal-primary"
        v-model="dialogProforma"
        title="Enregistrer un proforma"
        @hidden="resetProforma"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form class="mt-1" ref="venteForm" @submit.stop.prevent="saveProforma">
        <div>
          <form-wizard
              ref="form_wizard"
              :validateOnBack="true"
              back-button-text="Précédent"
              nextButtonText="Suivant"
              color="#27295c"
              :title="null"
              :subtitle="null"
              @on-complete="saveProforma"
              class="steps-transparent mb-3"
          >
            <!-- client tab -->
            <tab-content title="Client" :before-change="validationFormClient">
              <validation-observer ref="clientRules" tag="form">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Client" label-for="clientName">
                      <validation-provider
                          #default="{ errors }"
                          name="clientName"
                      >
                        <!-- <b-form-select
                          id="clientName"
                          v-model="sellForm.client"
                          :options="clientsList"
                          value-field="id"
                          text-field="name"
                          @input="client_nameSelectSellReset"
                          :state="errors.length > 0 ? false : null"
                        /> -->
                        <Select2
                            id="clientNom"
                            :options="[].slice.call(clients).map(_c => { return {value: _c.id, text: _c.name} })"
                            dropDownParent="#proforma-modal___BV_modal_content_"
                            lang="fr"
                            :change="checkClientData"
                        />
                        <!-- <b-form-input
                          list="clientsList"
                          id="clientName"
                          v-model="sellForm.client"
                          @change="client_nameSelectSellReset()"
                          :state="errors.length > 0 ? false : null"
                        />
                        <b-form-datalist id="clientsList" :options="clientsList"></b-form-datalist> -->
                        <small class="text-danger">{{
                            errors[0]
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col class="px-0 mt-2 col-2">
                    <b-button
                        variant="outline-dark"
                        size="md"
                        class=""
                        @click="modalAddClient = true"
                    >
                      Nouveau
                    </b-button>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                        label="Téléphone"
                        label-for="clientPhone"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="clientPhone"
                          rules=""
                      >
                        <b-form-input
                            id="clientPhone"
                            v-model="client_phone"
                            @keyup="checkClientDataPhone()"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Téléphone"
                        />
                        <small class="text-danger">{{
                            errors[0]
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Ifu" label-for="clientIfu">
                      <validation-provider
                          #default="{ errors }"
                          name="clientIfu"
                          rules=""
                      >
                        <b-form-input
                            id="clientIfu"
                            v-model="client_ifu"
                            type="text"
                            :disabled="true"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Ifu"
                        />
                        <small class="text-danger">{{
                            errors[0]
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Reccm" label-for="clientReccm">
                      <validation-provider
                          #default="{ errors }"
                          name="clientReccm"
                          rules=""
                      >
                        <b-form-input
                            id="clientReccm"
                            v-model="client_reccm"
                            type="text"
                            disabled="true"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Reccm"
                        />
                        <small class="text-danger">{{
                            errors[0]
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- signataire tab  -->
            <tab-content
                title="Signataire"
                :before-change="validationFormSignataire"
            >
              <validation-observer ref="signataireRules" tag="form">
                <b-row>
                  <b-col md="6">
                    <b-form-group class="col pr-0">
                      <validation-provider
                          #default="{ errors }"
                          name="signataireName"
                          rules="required"
                      >
                        <label for="signataireName">Nom signataire</label>
                        <b-form-select
                            id="signataire"
                            v-model="signataire"
                            :options="signataires"
                            value-field="name"
                            text-field="name"
                            @change="checkSignatairesData()"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!--                    <b-form-group
                                          label="Nom signataire"
                                          label-for="signataireName"
                                        >
                                          <validation-provider
                                            #default="{ errors }"
                                            name="signataireName"
                                            rules="required"
                                          >
                                            <b-form-select
                                                ref="signataires"
                                                v-model="venteForm.signataire"
                                                :options="signataires"
                                                value-field="name"
                                                text-field="name"
                                                @change="checkSignatairesData()"
                                                :state="errors.length > 0 ? false : null"
                                            >
                                            </b-form-select>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </b-form-group>-->
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Téléphone" label-for="signatairePhone">
                      <validation-provider
                          #default="{ errors }"
                          name="signatairePhone"
                          rules=""
                      >
                        <b-form-input
                            id="signatairePhone"
                            type="number"
                            value-field="name"
                            text-field="name"
                            @keyup="checkSignatairesDataPhone()"
                            v-model="signataire_phone"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Téléphone"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- details du paiement -->
            <tab-content
                title="Paiement"
                :before-change="validationFormDetails"
            >
              <validation-observer ref="detailsRules" tag="form">
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Prix de vente" label-for="prix_vente">
                      <validation-provider
                          #default="{ errors }"
                          name="prix_vente"
                          rules="required"
                      >
                        <cleave
                            class="form-control"
                            :raw="false"
                            id="prix_vente"
                            :options="numberFormat"
                            placeholder="0"
                            v-model="proformaForm.prix_vente"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
          </form-wizard>
        </div>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogProforma = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonProforma"
              variant="primary"
              class="mt-2"
              @click="saveProforma"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Modal pour la caution -->
    <b-modal
        size="lg"
        id="caution-modal"
        modal-class="modal-primary"
        v-model="dialogCaution"
        title="Paiement carte importateur"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form class="mt-1" ref="venteForm" @submit.stop.prevent="saveCaution">
        <b-row>
          <b-col>
            <label for="">Montant déjà payé</label>
            <b-button block variant="secondary">
              {{ $thousandSeparator(totalCautionPaid) }}
            </b-button>
          </b-col>
          <b-col>
            <validation-provider
                #default="{ errors }"
                name="montant"
                rules="required"
            >
              <label for="">Montant à payer</label>
              <cleave
                  id="montant"
                  class="form-control"
                  :raw="false"
                  :options="numberFormat"
                  placeholder="Montant à payer"
                  v-model="cautionForm.montant_paye_caution"
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <label for="">Total payé</label>
            <b-button block variant="secondary">
              {{ $thousandSeparator(totalCautionPaidNew) }}
            </b-button>
          </b-col>
        </b-row>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogCaution = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonSaveCaution"
              variant="primary"
              class="mt-2"
              @click="saveCaution"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Modal pour les demarcheurs -->
    <b-modal
        id="demarcheur-modal"
        size="lg"
        modal-class="modal-primary"
        v-model="dialogDemarcheur"
        title="Enregistrer un paiement demarcheur"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form
          class="mt-1"
          ref="venteForm"
          @submit.stop.prevent="saveDemarcheur"
      >
        <b-row>
          <b-col>
            <validation-provider
                #default="{ errors }"
                name="demarcheur"
                rules="required"
            >
              <label for="demarcheur">Démarcheur</label>
              <b-form-input
                  list="demarcheurs"
                  v-model="demarcheurForm.beneficiaire"
                  @input="checkDemarcheurData()"
                  :state="errors.length > 0 ? false : null"
              />
              <b-form-datalist id="demarcheurs" :options="demarcheurs">
              </b-form-datalist>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
                #default="{ errors }"
                name="telephone"
                rules="required"
            >
              <label for="">Téléphone</label>
              <!-- @input="checkDemarcheurDataPhone()" -->
              <b-form-input
                  id="telephone"
                  placeholder="Téléphone"
                  v-model="demarcheurForm.beneficiaire_phone"
                  @input="checkDemarcheurDataPhone()"
                  :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
                #default="{ errors }"
                name="montant"
                rules="required"
            >
              <label for="">Montant</label>
              <cleave
                  class="form-control"
                  :raw="false"
                  id="montant"
                  placeholder="0"
                  :options="numberFormat"
                  v-model="demarcheurForm.prix"
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogDemarcheur = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonDemarcheur"
              variant="primary"
              class="mt-2"
              @click="saveDemarcheur"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
        id="perte-modal"
        size="md"
        modal-class="modal-primary"
        v-model="dialogPerte"
        title="Enregistrer une perte"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form
          class="mt-1"
          ref="venteForm"
          @submit.stop.prevent="savePerte"
      >
        <b-row>
          <b-col>
            <validation-provider
                #default="{ errors }"
                name="montant"
                rules="required"
            >
              <label for="">Montant</label>
              <cleave
                  class="form-control"
                  :raw="false"
                  id="montant"
                  placeholder="0"
                  :options="numberFormat"
                  v-model="perteForm.prix"
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogPerte = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonPerte"
              variant="primary"
              class="mt-2"
              @click="savePerte"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Modal pour la carte -->
    <b-modal
        size="lg"
        id="carte-modal"
        modal-class="modal-primary"
        v-model="dialogCarte"
        title="Enregistrer une impression de carte importateur"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form class="mt-1" ref="venteForm" @submit.stop.prevent="saveCarte">
        <b-row>
          <b-col>
            <validation-provider
                #default="{ errors }"
                name="bl"
                rules="required"
            >
              <label for="">BL</label>
              <b-form-input
                  id="bl"
                  type="text"
                  placeholder="BL"
                  v-model="carteForm.bl"
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
                #default="{ errors }"
                name="montant"
                rules="required"
            >
              <label for="">Montant</label>

              <cleave
                  class="form-control"
                  :raw="false"
                  id="montant"
                  placeholder="0"
                  :options="numberFormat"
                  v-model="carteForm.montant"
                  :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogCarte = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonCarteImp"
              variant="primary"
              class="mt-2"
              @click="saveCarte"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Modal pour le client -->
    <b-modal
        id="client-modal"
        modal-class="modal-primary"
        v-model="dialogClient"
        title="Modifier le client"
        :ok-disabled="isOperating"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form class="mt-1" ref="venteForm" @submit.stop.prevent="saveClient">
        <b-row>
          <b-col>
            <b-form-group label="Client" label-for="client">
              <validation-provider
                  #default="{ errors }"
                  name="client"
                  rules="required"
              >
                <model-list-select
                    :list="clients"
                    id="client"
                    v-model="clientForm.client"
                    option-value="id"
                    option-text="name"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <b-form-group class="mb-0">
        <b-button
            variant="outline-secondary"
            type="reset"
            class="mt-2 mr-1"
            @click="dialogClient = false"
        >
          Annuler
        </b-button>

        <b-button
            v-if="etatButtonClient"
            variant="primary"
            class="mt-2"
            @click="saveClient"
        >
          Enregistrer
        </b-button>

        <b-button v-else variant="primary" class="mt-2">
          <b-icon-arrow-clockwise
              animation="spin"
              font-scale="1"
          ></b-icon-arrow-clockwise>
          En cours
        </b-button>
      </b-form-group>
    </b-modal>

    <!-- Modal de la Synthèse -->
    <b-modal
        size="lg"
        id="synthese-modal"
        header-text-variant="light"
        modal-class="modal-primary"
        v-model="dialogSynthese"
        title="Synthèse"
        cancel-variant="outline-secondary"
        ok-title="OKAY"
        ok-variant="primary"
        ok-only
        @ok="dialogSynthese = false"
        centered
    >
      <b-card no-body title="INFORMATIONS" class="mt-1">
        <b-alert class="mx-1 mt-2" show variant="primary">
          <div class="alert-body">
            <feather-icon class="mr-25" icon="InfoIcon" />
            <span class="ml-25 justify-space-between">
              <strong class="mr-auto">PRIX DE REVIENT </strong>
              {{ $thousandSeparator(prix_revient_calcule) }}
              {{ vehicule.dev_conversion }}
            </span>
          </div>
        </b-alert>
        <div class="row px-2">
          <div class="col-5 ml-1 border-right">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-archive
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-archive>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">ACHAT</h6>
                <small>
                  {{ $thousandSeparator(vehicule.prix_achat) }}
                  {{ vehicule.dev_achat }}</small
                >
              </b-media-body>
            </b-media>
          </div>
          <div class="col-5 ml-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-truck-flatbed
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-truck-flatbed>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">TRANSPORT</h6>
                <small>
                  {{ $thousandSeparator(vehicule.prix_transport) }}
                  {{ vehicule.dev_transport }}</small
                >
              </b-media-body>
            </b-media>
          </div>
          <div class="mt-2 col-5 ml-1 border-right">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-tablet-landscape
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-tablet-landscape>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">CARTE IMPORTATEUR</h6>
                <small>
                  {{ $thousandSeparator(vehicule.prix_carte_imp) }}
                  {{ vehicule.dev_carte_imp }}</small
                >
              </b-media-body>
            </b-media>
          </div>
          <div class="mt-2 col-5 ml-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-cash
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-cash>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">FRAIS</h6>
                <small>
                  {{ $thousandSeparator(vehicule.frais) }}
                  {{ vehicule.dev_frais }}</small
                >
              </b-media-body>
            </b-media>
          </div>
          <div class="mt-2 col-5 ml-1 border-right">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-cash-stack
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-cash-stack>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">TOTAL DES ACHATS</h6>
                <small>
                  {{ $thousandSeparator(totalPrixAchat) }}
                  {{ vehicule.dev_conversion }}
                </small>
              </b-media-body>
            </b-media>
          </div>
          <div class="mt-2 col-5 ml-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-arrow-down-up
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-arrow-down-up>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">TOTAL DES DEPENSES</h6>
                <small>
                  {{ $thousandSeparator(total_depenses) }}
                  {{ vehicule.dev_conversion }}
                </small>
              </b-media-body>
            </b-media>
          </div>
            <div class="mt-2 col-5 ml-1">
                <b-media no-body>
                    <b-media-aside class="mr-1">
                        <b-icon-graph-down
                            class="h3 text-primary"
                            style="margin-top: 5%; margin-right: 10px"
                        ></b-icon-graph-down>
                    </b-media-aside>
                    <b-media-body>
                        <h6 class="mb-0">PERTE DE VALEUR</h6>
                        <small>
                            {{ $thousandSeparator(fraisPerte) }}
                            {{ vehicule.dev_conversion }}</small
                        >
                    </b-media-body>
                </b-media>
            </div>
        </div>
        <b-alert
            class="mx-1 mt-2"
            show
            :variant="isBenOrNot == 'PERTE' ? 'danger' : 'success'"
        >
          <div class="alert-body">
            <feather-icon class="mr-25" icon="InfoIcon" />
            <span class="ml-25">
              <strong
              >{{ isBenOrNot }} {{ vehicule.prix_vente ? "" : "PREVU" }}
              </strong>
              {{
                benefice
                    ? $thousandSeparator(benefice)
                    : "Prix de vente non renseigné"
              }}{{ vehicule.dev_conversion }}
            </span>
          </div>
        </b-alert>
        <div class="row mt-2">
          <div class="col-5 ml-1 border-right">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-arrow-bar-up
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-arrow-bar-up>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  P. VENTE
                  <span
                      v-if="
                      vehicule.statut !== 'vendu' &&
                      vehicule.statut !== 'livré' &&
                      vehicule.statut !== 'en cours de vente'
                    "
                  >PREVU</span
                  >
                </h6>
                <small>
                  {{
                    vehicule.prix_vente
                        ? $thousandSeparator(vehicule.prix_vente)
                        : "Prix non renseigné"
                  }}
                  {{ vehicule.dev_vente }}</small
                >
              </b-media-body>
            </b-media>
          </div>
          <div class="col-5 ml-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-people
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-people>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">DEMARCHEURS</h6>
                <small>
                  {{ $thousandSeparator(fraisDemarcheur) }}
                  {{ vehicule.dev_conversion }}</small
                >
              </b-media-body>
            </b-media>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-5 ml-1 border-right">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-file
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-file>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">CARTE IMP</h6>
                <small>
                  {{ $thousandSeparator(c_imp) }}
                  {{ vehicule.dev_conversion }}</small
                >
              </b-media-body>
            </b-media>
          </div>
          <div class="col-5 ml-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-icon-reply-all
                    class="h3 text-primary"
                    style="margin-top: 5%; margin-right: 10px"
                ></b-icon-reply-all>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">P. VENTE FINAL</h6>
                <small>
                  {{ $thousandSeparator(prixVenteCalcule) }}
                  {{ vehicule.dev_conversion }}</small
                >
              </b-media-body>
            </b-media>
          </div>
          <div class="row mt-2">
            <div class="col-5 ml-1 ">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-icon-reply-all
                      class="h3 text-primary"
                      style="margin-top: 5%; margin-right: 10px"
                  ></b-icon-reply-all>
                </b-media-aside>
                <b-media-body v-if="$thousandSeparator(vehicule.prix_generation_facture) !== 0 ">
                  <h6 class="mb-0">Prix Géneration Facture</h6>
                  <small>
                    {{ $thousandSeparator(vehicule.prix_generation_facture) }}
                    {{ vehicule.dev_conversion }}</small
                  >
                </b-media-body>
              </b-media>
            </div>
          </div>
        </div>

        <b-alert
            v-if="
            vehicule.statut == 'vendu' ||
            vehicule.statut == 'livré' ||
            vehicule.statut == 'en cours de vente'
          "
            class="mx-1 mt-2"
            show
            variant="secondary"
        >
          <div class="alert-body">
            <feather-icon class="mr-25" icon="UserIcon" />
            <span class="ml-25">
              <strong>CLIENT </strong>
              <span class="ml-auto"
              >{{ voitureClient.name }} - {{ voitureClient.phone }}</span
              >
            </span>
          </div>
        </b-alert>
      </b-card>
    </b-modal>

    <!-- Modal pour les details -->
    <b-modal
        size="lg"
        id="modal-details-vehicule"
        v-model="dialogDetails"
        title="Modifier les détails"
        :ok-disabled="isOperating"
        @hidden="resetDetails"
        hide-footer
        centered
    >
      <b-form class="mt-1" ref="editForm" @submit.stop.prevent="saveDetails">
        <b-row
            v-if="vehicule.statut == 'en stock' || vehicule.statut == 'en dépôt'"
        >
          <b-col class="col-6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="prix_vente">
                <label for="prix_vente">Prix de vente prévu</label>

                <cleave
                    class="form-control"
                    :raw="false"
                    id="prix_vente"
                    :options="numberFormat"
                    v-model="detailsForm.prix_vente"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="annee"
                  rules="required"
              >
                <label for="annee">Prix de revient</label>
                <b-button block variant="secondary">
                  {{ $thousandSeparator(detailsForm.prix_revient) }}
                </b-button>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-alert
            class=""
            :show="vehicule.statut == 'en stock' || vehicule.statut == 'en dépôt'"
            :variant="couleurCrisconstancielle"
        >
          <div class="alert-body">
            <feather-icon class="mr-25" icon="InfoIcon" />
            <span class="ml-25 my-2 justify-space-between">
              {{ texteCirconstanciel }}
            </span>
          </div>
        </b-alert>
        <b-row>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="marque"
                  rules="required"
              >
                <label for="marque">Marque</label>
                <model-list-select
                    :list="marques"
                    id="client"
                    v-model="detailsForm.marque"
                    option-value="name"
                    option-text="name"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="annee"
                  rules="required"
              >
                <label for="annee">Année</label>
                <b-form-input
                    id="annee"
                    type="text"
                    placeholder="Année"
                    v-model="detailsForm.annee"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="annee"
                  rules="required"
              >
                <label for="annee">Chassis</label>
                <b-form-input
                    id="annee"
                    type="text"
                    placeholder="Année"
                    v-model="detailsForm.numero_chassis"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="couleur"
                  rules="required"
              >
                <label for="couleur">Couleur</label>
                <model-list-select
                    :list="couleurs"
                    id="client"
                    v-model="detailsForm.couleur"
                    option-value="name"
                    option-text="name"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="carburant">
                <label for="carburant">Carburant</label>
                <b-form-select
                    id="carburant"
                    v-model="detailsForm.carburant"
                    :options="carburants"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="boite_vitesse">
                <label for="boite_vitesse">Boite à vitesse</label>
                <b-form-select
                    id="boite_vitesse"
                    v-model="detailsForm.boite_vitesse"
                    :options="boite_vitesses"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="nbr_portes">
                <label for="nbr_portes">Nombre de portes</label>
                <b-form-input
                    id="nbr_portes"
                    type="text"
                    placeholder="Nombre de portes"
                    v-model="detailsForm.nbr_portes"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="cylindre">
                <label for="cylindre">Cylindre</label>
                <b-form-select
                    id="cylindre"
                    v-model="detailsForm.puissance_fiscale"
                    :options="cylindres"
                    :state="errors.length > 0 ? false : null"
                />
                <!-- <b-form-input
                  id="cylindre"
                  type="text"
                  placeholder="Cylindre"
                  v-model="detailsForm.puissance_fiscale"
                  :state="errors.length > 0 ? false : null"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="kilometrage">
                <label for="kilometrage">Kilométrage</label>
                <b-form-input
                    id="kilometrage"
                    type="text"
                    placeholder="Kilométrage"
                    v-model="detailsForm.kilometrage"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-12">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="caracteristiques"
              >
                <label for="caracteristiques"
                >Caracteristiques supplémentaires</label
                >
                <b-form-textarea
                    id="caracteristiques"
                    placeholder="Caracteristiques"
                    v-model="detailsForm.caracteristiques"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogDetails = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonDetails"
              variant="primary"
              class="mt-2"
              @click="saveDetails"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Modal pour les prix -->
    <b-modal
        size="lg"
        id="modal-edit-vehicule"
        v-model="dialogUpdate"
        title="Modifier information voiture"
        :ok-disabled="isOperating"
        @hidden="resetUpdate"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form class="mt-1" ref="editForm" @submit.stop.prevent="saveUpdate">
        <b-row>
          <b-col class="col-12">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="numero_chassis"
                  rules="required"
              >
                <label for="numero_chassis">Numero chassis</label>
                <b-form-input
                    id="numero_chassis"
                    type="text"
                    v-model="updateForm.numero_chassis"
                    placeholder="Numero chassis"
                    :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="prix_achat"
                  rules="required"
              >
                <label for="prix_achat">Achat</label>
                <b-input-group>
                  <cleave
                      class="form-control"
                      :raw="false"
                      id="prix_achat"
                      placeholder="0"
                      :options="numberFormat"
                      @input="checkPriceChange()"
                      v-model="updateForm.prix_achat"
                      :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append is-text>
                    {{ updateForm.dev_achat }}
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="frais"
                  rules="required"
              >
                <label for="frais">Frais</label>
                <b-input-group>
                  <cleave
                      class="form-control"
                      :raw="false"
                      id="frais"
                      placeholder="0"
                      :options="numberFormat"
                      @input="checkPriceChange()"
                      v-model="updateForm.frais"
                      :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append is-text>
                    {{ updateForm.dev_frais }}
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="prix_transport"
                  rules="required"
              >
                <label for="prix_transport">Transport</label>
                <b-input-group>
                  <cleave
                      class="form-control"
                      :raw="false"
                      id="prix_transport"
                      placeholder="0"
                      :options="numberFormat"
                      @input="checkPriceChange()"
                      v-model="updateForm.prix_transport"
                      :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append is-text>
                    {{ updateForm.dev_transport }}
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-6">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="dev_carte_imp"
                  rules="required"
              >
                <label for="dev_carte_imp">Carte importateur</label>
                <b-input-group>
                  <cleave
                      class="form-control"
                      :raw="false"
                      id="prix_carte_imp"
                      placeholder="0"
                      :options="numberFormat"
                      @input="checkPriceChange()"
                      v-model="updateForm.prix_carte_imp"
                      :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append is-text>
                    {{ updateForm.dev_carte_imp }}
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="dialogUpdate = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonModifierPrix"
              variant="primary"
              class="mt-2"
              @click="saveUpdate"
          >
            Modifier
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- modal add client start  -->
    <b-modal
        id="modal-addClient"
        v-model="modalAddClient"
        title="Ajouter un nouveau client"
        @hidden="resetModalAddClient"
        hide-footer
        centered
    >
      <!-- form start -->
      <b-form ref="addNewClient" @submit.prevent>
        <b-form-group>
          <validation-provider #default="{ errors }" name="name">
            <label for="name">Nom et Prénom</label>
            <b-form-input
                id="name"
                placeholder="Nom et Prénom"
                type="text"
                v-model="newClient.name"
                :state="errors.length > 0 ? false : null"
                @click="resetInputNameModal"
            />
            <small class="text-danger"
            >{{ errors[0] ? errors[0] : errorsNameModal }}
            </small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
              #default="{ errors }"
              name="phone"
              rules="required"
          >
            <label for="phone">Téléphone</label>
            <b-form-input
                id="phone"
                type="tel"
                v-model="newClient.phone"
                placeholder="Téléphone"
                :state="errors.length > 0 ? false : null"
                @click="resetInputPhoneModal"
            />
            <small class="text-danger"
            >{{ errors[0] ? errors[0] : errorsPhoneModal }}
            </small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider #default="{ errors }" name="ifu">
            <label for="ifu">Ifu</label>
            <b-form-input
                id="ifu"
                type="text"
                v-model="newClient.ifu"
                placeholder="Ifu"
                :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger"
            >{{ errors[0] ? errors[0] : errorsIfuModal }}
            </small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider #default="{ errors }" name="reccm">
            <label for="reccm">Reccm</label>
            <b-form-input
                id="reccm"
                type="text"
                v-model="newClient.reccm"
                placeholder="Reccm"
                :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger"
            >{{ errors[0] ? errors[0] : errorsReccmModal }}
            </small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mb-0">
          <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="modalAddClient = false"
          >
            Annuler
          </b-button>

          <b-button
              v-if="etatButtonAddClient"
              variant="primary"
              class="mt-2"
              @click="addNewClient"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
      <!-- form end -->
    </b-modal>

    <!-- modal add client end -->
    <b-button
        @click="$router.go(-1)"
        variant="outline-warning"
        class="btn-icon my-1"
        size="sm"
    >
      <span class="">RETOUR</span>
    </b-button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Cleave from "vue-cleave-component";
import Select2 from "@/components/Select2.vue";
import { ModelListSelect } from "vue-search-select";
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  ComboBoxComponent,
  ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";
import {
    BIconPlus,
    BCard,
    BCardHeader,
    BTable,
    BRow,
    BCardTitle,
    BAlert,
    BCardBody,
    BAvatar,
    BMediaAside,
    BIconArchive,
    BIconPeople,
    BIconFile,
    BMediaBody,
    BCol,
    BButton,
    BModal,
    BMedia,
    BForm,
    BFormFile,
    BCardText,
    BBadge,
    BFormInput,
    BIconArrowBarUp,
    BFormGroup,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BFormDatalist,
    BIconCalendarEvent,
    BIconArrowDownUp,
    BIconCapslock,
    BIconCash,
    BFormTextarea,
    BIconTruckFlatbed,
    BIconTabletLandscape,
    BIconCalendarRange,
    BIconDice1,
    BIconArrowClockwise,
    BTooltip,
    BCarousel,
    BFormRadio,
    BCarouselSlide,
    BInputGroupAppend,
    BIconDoorOpen,
    BIconCashStack,
    BIconReplyAll,
    BIconAward,
    BIconAlt,
    BIconCollection,
    BIconCompass,
    BIconLayers,
    BIconColumnsGap,
    BIconBarChartSteps,
    BIconAlarm,
    BInputGroup,
    BFormDatepicker,
    BIconCircleFill,
    BIconCartPlus,
    VBTooltip,
    BFormSelectOption,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback, BIconGraphDown,
} from "bootstrap-vue";
export default {
  data: () => ({
    btnSave: 0,
    src: require("@/assets/images/car1.jpg"),
    etatButtonDepense: true,
    etatButtonGallerie: true,
    etatButtonModifierPrix: true,
    etatButtonSaveCaution: true,
    etatButtonCarteImp: true,
    etatButtonDetails: true,
    etatButtonSell: true,
    etatButtonProforma: true,
    etatButtonClient: true,
    etatButtonSortie: true,
    etatButtonDemarcheur: true,
    etatButtonPerte: true,
    etatButtonRecouvrement: true,
    numberFormat: {
      numeral: true,
      delimiter: " ",
      numeralThousandsGroupStyle: "thousand",
    },
    perPage: 10,
    currentPage: 1,
    filter: "",
    newvoice: "",
    mode_solde: "solderauplustard",

    disable_add_client: true,
    remise: 0,
    montant_paye: 0,
    pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],

    scrollInvoked: 0,
    selectedFile: [],
    isSelecting: false,
    noChange: true,

    updateForm: {},
    depenseForm: {},
    venteForm: {},
    signataire: null,
    clientForm: {},
    gallerieForm: {},
    cautionForm: {},
    demarcheurForm: {},
    perteForm: {},
    detailsForm: {},
    carteForm: {},
    sortieForm: {},
    proformaForm: {},
    recouvrirForm: {},

    standardizedInvoiceGenerated: false,

    dialogCarte: false,
    dialogPrint: false,
    dialogVente: false,
    dialogUpdate: false,
    dialogDetails: false,
    dialogSortie: false,
    dialogClient: false,
    dialogDepense: false,
    dialogRecouvrir: false,
    dialogProforma: false,
    dialogSynthese: false,
    dialogGalerie: false,
    dialogDelete: false,
    dialogCaution: false,
    dialogDemarcheur: false,
    dialogPerte: false,

    depenses: [],
    marques: [],
    couleurs: [],
    factures: [],
    societeObjet: {},
    parcs: [],
    fieldsComboClients: { text: "name", value: "id" },
    fieldsComboSignataires: { text: "name", value: "id" },
    carburants: ["Essence", "Diesel", "Hydrogen", "Electricité"],
    boite_vitesses: ["Manuel", "Automatique"],
    cylindres: ["V4", "V6", "V8", "V12"],
    clients: [],
    signataires: [],
    modalAddClient: false,
    etatButtonAddClient: true,
    Isimportateur:0,
    errorsNameModal: null,
    errorsPhoneModal: null,
    errorsIfuModal: null,
    errorsReccmModal: null,
    newClient: {},
    traiementTypes: [
      { text: "Traitement simple", value: "0" },
      { text: "Traitement special", value: "1" },
    ],
    venteTypes: [
      { text: "Transit", value: "Transit" },
      { text: "Local", value: "Local" },
    ],
    factureTypes: [
      { text: "Facture normalisée", value: "Facture normalisée" },
      { text: "Facture simple", value: "Facture simple" },
    ],
    paillotes: [],
    haveParc: true,
    voitureClient: {},
    client: null,
    client_reccm: null,
    client_ifu: null,
    client_phone: null,
    signataire_phone: null,
    havePaillote: true,
    activeCheckBox: 0,

    vehicule: [],
    manifeste: [],
    gallerie: [],

    progress: false,

    headersDepenses: [
      {
        label: "Date",
        sortable: true,
        key: "created_at",
      },
      {
        label: "Description",
        sortable: true,
        key: "description",
      },
      {
        label: "Montant",
        sortable: true,
        key: "prix",
      },
    ],

    headersFactures: [
      {
        label: "N°",
        sortable: true,
        key: "facture_no",
      },
      {
        label: "Date",
        sortable: true,
        key: "created_at",
      },
      {
        label: "Vente",
        sortable: true,
        key: "montant_vente",
      },
      {
        label: "Avance perçue",
        sortable: true,
        key: "avance_percue",
      },
      {
        label: "Montant payé",
        sortable: true,
        key: "montant_paye",
      },
      {
        label: "RAP",
        sortable: true,
        key: "reste_a_paye",
      },
      {
        label: "Actions",
        key: "actions",
      },
    ],
    isOperating: false,

    editedItem: {
      name: "",
    },
    deletedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
  }),

  components: {
      BIconGraphDown,
    Cleave,
    ModelListSelect,
    FormWizard,
    BAlert,
    BFormFile,
    BFormRadio,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BIconReplyAll,
    BCardBody,
    TabContent,
    BMedia,
    BCardTitle,
    BIconFile,
    VueSweetalert2,
    BIconTruckFlatbed,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BIconCalendarEvent,
    BIconCashStack,
    BIconBarChartSteps,
    BIconAlt,
    Select2,
    BIconDoorOpen,
    BIconCapslock,
    BIconArrowClockwise,
    BIconCalendarRange,
    BCardHeader,
    BIconDice1,
    BIconCollection,
    BFormTextarea,
    BIconArrowDownUp,
    BIconCompass,
    BIconPeople,
    BIconTabletLandscape,
    BIconLayers,
    BIconCash,
    BIconColumnsGap,
    BIconArchive,
    BIconAward,
    BCarousel,
    BCarouselSlide,
    EmptyList,
    BIconAlarm,
    ComboBoxComponent,
    ComboBoxPlugin,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BFormDatalist,
    BIconArrowBarUp,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BBadge,
    BIconCartPlus,
    BFormDatepicker,
    BIconCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },

  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),

    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);
      return maxDate;
    },

    recouvrirRapFinal() {
      if (
          this.recouvrirForm.reste_a_paye ||
          this.$thousandFormater(this.recouvrirForm.montant_paye)
      ) {
        let num1 = this.recouvrirForm.reste_a_paye
            ? this.recouvrirForm.reste_a_paye
            : 0;
        let num2 = this.$thousandFormater(this.recouvrirForm.montant_paye)
            ? this.$thousandFormater(this.recouvrirForm.montant_paye)
            : 0;

        return num1 - num2;
      } else return 0;
    },

    texteCirconstanciel() {
      let p_v = this.$thousandFormater(this.detailsForm.prix_vente);
      let p_r = this.$thousandFormater(this.detailsForm.prix_revient);

      if (p_r > p_v) {
        return "Attention ! Une vente à ce prix ne va générer aucun bénéfice. ";
      } else if (p_v == p_r) {
        return "Très bien ! Le prix de revient est égal au prix de vente prévu. ";
      } else if (p_v > p_r) {
        return (
            "À ce prix, vous effectuez un bénéfice de " +
            this.$thousandSeparator(p_v - p_r) +
            " sur ce véhicule. "
        );
      }
      return "";
    },

    couleurCrisconstancielle() {
      let p_v = this.$thousandFormater(this.detailsForm.prix_vente);
      let p_r = this.$thousandFormater(this.detailsForm.prix_revient);

      if (p_r > p_v) {
        return "danger";
      } else if (p_v == p_r) {
        return "warning";
      } else if (p_v > p_r) {
        return "success";
      }

      return "dark";
    },

    //Montant avant paiement
    totalCautionPaid() {
      return this.formatAmount(this.cautionForm.caution);
    },

    //Montant après paiement
    totalCautionPaidNew() {
      let num = this.formatAmount(this.cautionForm.montant_paye_caution);
      return this.totalCautionPaid + num;
    },

    checkDateLimiteRecouvrement() {
      if (parseInt(this.recouvrirRapFinal) > 0) return true;
      else return false;
    },

    checkDateLimite() {
      let num1 = this.formatAmount(this.netapayer);
      let num2 = this.formatAmount(this.montant_paye);

      if (num1 > num2) {
        this.datelimite = true;
        this.mode_solde = "solderauplustard";
        return true;
      } else {
        this.mode_solde = "solderavantsortie";
        this.datelimite = false;
        return false;
      }
    },

    netapayer() {
      return this.formatAmount(this.venteForm.prix_vente);
    },

    rap() {
      let num1 = this.$thousandFormater(this.netapayer);
      let num2 = this.$thousandFormater(this.montant_paye);
      return num1 - num2;
    },

    existDateLimite() {
      if (this.mode_solde == "solderauplustard") return true;
      return false;
    },

    disableVenteTypes() {
      if (this.venteForm.venteType == "Facture simple") {
        return true;
      } else {
        return false;
      }
    },

    currentItems() {
      return this.initialItems;
    },

    options() {
      return {
        distractionFree: false,
        valueAsInteger: true,
        AllowNegative: false,
        autoDecimalMode: true,
        currency: null,
        precision: 0,
      };
    },
    isBenOrNot() {
      if (this.benefice < 0) return "PERTE";
      return "BENEFICE";
    },

    total_depenses() {
      let total_dep = 0;
      if (this.depenses) {
        this.depenses.forEach((element) => {
          if (element.type != "Paiement démarcheur" && element.type != "Perte de valeur" && element.type != "Paiement Facture normalisée") {
            total_dep += parseInt(element.prix);
          }
        });
      }
      return total_dep;
    },

    totalPrixAchat() {
      return (
          this.vehicule.conv_achat +
          this.vehicule.conv_carte_imp +
          this.vehicule.conv_frais +
          this.vehicule.conv_transport
      );
    },

    prix_revient_calcule() {
      return ((this.totalPrixAchat + this.total_depenses)- this.fraisPerte);
    },

    fraisDemarcheur() {
      let total_dem = 0;
      if (this.depenses) {
        this.depenses.forEach((element) => {
          if (element.type == "Paiement démarcheur") {
            total_dem += parseInt(element.prix);
          }
        });
      }
      return total_dem;
    },
      fraisPerte() {
      let total_perte = 0;
      if (this.depenses) {
        this.depenses.forEach((element) => {
          if (element.type == "Perte de valeur") {
              total_perte += parseInt(element.prix);
          }
        });
      }
      return total_perte;
    },

    c_imp() {
      if (this.vehicule.detail_vente) {
        let caution = this.vehicule.detail_vente.caution;
        return caution ? caution : 0;
      }
      return 0;
    },

    prixVenteCalcule() {
      if (!this.vehicule.prix_vente) return "Prix de vente non renseigné";
      return (
          this.$thousandFormater(this.vehicule.prix_vente) -
          this.fraisDemarcheur +
          this.c_imp -
          this.$thousandFormater(this.vehicule.prix_generation_facture)

      );
    },

    benefice() {
      // if (this.vehicule.benefice) return this.vehicule.benefice
      // else if (!this.vehicule.prix_vente) return 'Prix de vente non renseigné'
      // else if(this.vehicule.statut == 'vendu' || this.vehicule.statut == 'livré' || this.vehicule.statut == 'en cours de vente')
      // return this.vehicule.prix_vente - this.prix_revient_calcule
      return this.prixVenteCalcule - this.prix_revient_calcule;
    },

    showBenefice() {
      if (
          this.vehicule.statut == "vendu" ||
          this.vehicule.statut == "en cours de vente"
      )
        return true;
      else return false;
    },
  },

  mounted() {
    this.getSociete();
    this.vehiculeData();
    this.getSignataires();
    this.getClients();
    this.getDemarcheurs();
    this.marquesData();
    this.couleursData();
  },

  methods: {

    reloadPage() {
      location.reload();
    },
    activeTabIndex(data) {
      this.btnSave = data;
    },
    async marquesData() {
      await this.$http.get("/marques").then((response) => {
        this.marques = response.data.data;
      });
    },

    addNewClient() {
      this.etatButtonAddClient = false;

      this.$http
          .post("/clients", this.newClient)
          .then((result) => {
            if (result.data.success) {
              this.etatButtonAddClient = true;
              this.resetInputModal();
              this.newClient = JSON.parse(
                  JSON.stringify({ type: Object, default: () => {} })
              );
              this.showToast("Client ajouté avec succès", "success");
              this.getClients();
              this.modalAddClient = false;
            } else {
              this.etatButtonAddClient = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonAddClient = true;
            var err = e.response.data.errors;

            if (err) {
              if (err.name) {
                this.errorsNameModal = err.name[0];
              }

              if (err.phone) {
                this.errorsPhoneModal = err.phone[0];
              }

              if (err.ifu) {
                this.errorsIfuModal = err.ifu[0];
              }

              if (err.reccm) {
                this.errorsReccmModal = err.reccm[0];
              }
            }
          });
    },

    resetModalAddClient() {
      this.newClient.name = "";
      this.newClient.phone = "";
      this.newClient.ifu = "";
      this.newClient.reccm = "";
      this.resetInputModal();
    },

    resetInputModal() {
      this.resetInputNameModal();
      this.resetInputPhoneModal();
      this.resetInputIfuModal();
      this.resetInputReccmModal();
    },

    resetInputNameModal() {
      if (this.errorsNameModal) {
        this.errorsNameModal = null;
      }
    },

    resetInputPhoneModal() {
      if (this.errorsPhoneModal) {
        this.errorsPhoneModal = null;
      }
    },

    resetInputIfuModal() {
      if (this.errorsIfuModal) {
        this.errorsIfuModal = null;
      }
    },

    resetInputReccmModal() {
      if (this.errorsReccmModal) {
        this.errorsReccmModal = null;
      }
    },

    //get all Couleurs
    async couleursData() {
      await this.$http.get("/couleurs").then((response) => {
        this.couleurs = response.data.data;
      });
    },
    saveGallerie(bvModalEvt) {
      bvModalEvt.preventDefault();

      const formData = new FormData();

      formData.append("images", this.gallerieForm.images);

      for (let i = 0; i < this.gallerieForm.images.length; i++) {
        console.log(this.gallerieForm.images[i]);
        formData.append(`images[${i}]`, this.gallerieForm.images[i]);
      }

      formData.append("id", this.vehicule.id);

      this.isOperating = true;

      this.etatButtonGallerie = false;

      this.$http
          .post(`/gallery`, formData)
          .then((result) => {
            this.isOperating = false;
            this.vehiculeData();
            if (result.data.success == true) {
              this.etatButtonGallerie = true;
              this.showToast(
                  "La modification a été enregistrée avec succès!",
                  "success"
              );
              this.dialogGalerie = false;
              this.reloadPage();
            } else {
              this.etatButtonGallerie = true;
              let error = result.data.message;
              error = error[Object.keys(error)[0]][0];
              this.showToast(error, "danger");
            }
          })
          .catch((err) => {
            this.etatButtonGallerie = true;
            this.isOperating = false;
          });
    },
    saveCarte(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;
      this.carteForm.montant = this.formatAmount(this.carteForm.montant);
      this.carteForm.societe=this.societeObjet
      this.etatButtonCarteImp = false;

      this.$http
          .post("/imprimer-carte-imp-vehicule", this.carteForm)
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.etatButtonCarteImp = true;
              let routeData = this.$router.resolve({
                name: "importation-print-carte",
                query: this.carteForm,
              });
              window.open(routeData.href, "_blank");
              this.showToast("Impression enregistrée avec succès", "success");
              this.vehiculeData();
              this.resetCarte();
            } else {
              this.etatButtonCarteImp = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonCarteImp = true;
            this.isOperating = false;
            var err = e.response.data.message;
            this.showToast(err, "danger");
          });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    saveProforma(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;
      this.proformaForm.prix_vente = this.formatAmount(
          this.proformaForm.prix_vente
      );

      this.proformaForm.client = this.client;
      this.proformaForm.client_reccm = this.client_reccm;
      this.proformaForm.client_ifu = this.client_ifu;
      this.proformaForm.client_phone = this.client_phone;
      this.proformaForm.signataire = this.signataire;
      this.proformaForm.signataire_phone = this.signataire_phone;

      this.etatButtonProforma = false;

      this.$http
          .post("/pro-factures", this.proformaForm)
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.etatButtonProforma = true;
              let id = result.data.data.id;
              let routeData = this.$router.resolve({
                name: "proforma-show",
                params: { id: id },
              });
              window.open(routeData.href, "_blank");
              this.showToast("Proforma enregistré avec succès", "success");
              this.vehiculeData();
              this.resetProforma();
              this.resetClientFields();
            } else {
              this.etatButtonProforma = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonProforma = true;
            this.isOperating = false;
            var err = e.response.data.message;
            this.showToast(err, "danger");
          });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    saveCaution(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;
      this.cautionForm.montant_paye_caution = this.formatAmount(
          this.cautionForm.montant_paye_caution
      );
      this.cautionForm.societe=this.societeObjet
      this.etatButtonSaveCaution = false;

      this.$http
          .put(
              "/payer-carte-imp-vehicule/" + this.cautionForm.id,
              this.cautionForm
          )
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.etatButtonSaveCaution = true;
              this.showToast("Paiement enregistré avec succès", "success");
              this.vehiculeData();
              this.resetCaution();
            } else {
              this.etatButtonSaveCaution = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonSaveCaution = true;
            this.isOperating = false;
            var err = e.response.data.message;
            this.showToast(err, "danger");
          });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    saveSortie(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;

      this.etatButtonSortie = false;

      this.$http
          .post("/livrer-vehicule", this.sortieForm)
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.etatButtonSortie = true;
              this.showToast("Sortie enregistrée avec succès", "success");
              this.vehiculeData();
              this.resetSortie();
            } else {
              this.etatButtonSortie = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonSortie = true;
            this.isOperating = false;
            var err = e.response.data.message;
            this.showToast(err, "danger");
          });
    },

    saveClient() {
      if (this.clientForm.oldClient == this.clientForm.client) {
        this.showToast(
            "Le client selectionné ne peut être le même que celui enregistré.",
            "danger"
        );
      } else {
        this.$bvModal
            .msgBoxConfirm("Vous êtes sur le point de modifier le client.", {
              title: "Modification du client",
              okVariant: "success",
              cancelVariant: "outline-secondary",
              okTitle: "Confirmer",
              cancelTitle: "Annuler",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value) {
                this.etatButtonClient = false;

                this.$http
                    .put("/modifier-client/" + this.clientForm.id, this.clientForm)
                    .then((result) => {
                      this.isOperating = false;
                      if (result.data.success) {
                        this.etatButtonClient = true;
                        this.showToast(
                            "Modification enregistrée avec succès",
                            "success"
                        );
                        this.vehiculeData();
                        this.resetClient();
                      } else {
                        this.etatButtonClient = true;
                        this.showToast(result.data.message, "danger");
                      }
                    })
                    .catch((e) => {
                      this.etatButtonClient = true;
                      this.isOperating = false;
                      console.log("erreur modification:", e.response.data);
                      var err = e.response.data.message;
                      this.showToast(err, "danger");
                    });
              } else {
                //   this.dialogClient = true;
              }
            });
      }
    },

    showItem(item) {
      let routeData = this.$router.resolve({
        name: "facture-show",
        params: { id: item.id },
      });
      window.open(routeData.href, "_blank");
    },

    seeFacture() {
      let item = {
        id: this.vehicule.facture[this.vehicule.facture.length - 1].id,
      };

      this.showItem(item);
    },

    checkPrice() {
      this.venteForm.montant_paye = this.formatAmount(this.montant_paye);
      this.venteForm.net_a_paye = this.formatAmount(this.netapayer);
      this.venteForm.reste_a_paye = this.formatAmount(this.rap);
      this.venteForm.remise = this.remise;

      let num1 = this.formatAmount(this.montant_paye);
      let num2 = this.formatAmount(this.venteForm.prix_vente);

      if (num1 > num2) {
        this.showToast(
            "Le montant payé ne peut être supérieur au prix de vente du véhicule",
            "danger"
        );
        this.montant_paye = 0;
      }
    },

    checkPriceAlert() {
      let num2 = this.formatAmount(this.venteForm.prix_vente);

      if (this.venteForm.prix_revient > num2) {
        this.showToast(
            "ATTENTION : Le prix de revient de ce véhicule est : " +
            this.$thousandSeparator(this.venteForm.prix_revient),
            "danger"
        );
      }
    },
    checkClientData(e) {
      const _id = e.target.value;
      const selected = this.clients.find(_c => _c.id == _id);
      if (selected) {
        this.client = selected
        this.client_reccm = selected.reccm;
        this.client_ifu = selected.ifu;
        this.client_phone = selected.phone;
      }
    },

    /*checkClientData() {
      let chosen;
      if (this.client) {
        chosen = this.clients.find(({ text }) => text === this.client);
        if (chosen) {
          this.client = this.client;
          this.client_reccm = chosen.reccm;
          this.client_ifu = chosen.ifu;
          this.client_phone = chosen.phone;
        }
      } else {
        this.client = null;
        this.client_reccm = null;
        this.client_ifu = null;
        this.client_phone = null;
      }
    },*/

    checkClientDataPhone() {
      let chosen;
      if (this.client_phone) {
        chosen = this.clients.find(({ phone }) => phone === this.client_phone);
      }
        console.log(chosen)
      //   console.log("checkClientDataPhone checkClientDataPhone")
      if (chosen) {
        let data = {
          value: chosen.id,
          text: chosen.name
        };
        let newOption = new Option(data.text, data.value, false, true);
        $('#clientNom').append(newOption).trigger('change');
        this.client = chosen;
        this.client_reccm = chosen.reccm;
        this.client_ifu = chosen.ifu;
      } else {
      }
    },

    checkSignatairesDataPhone() {
      let chosen;
      if (this.signataire_phone) {
        chosen = this.signataires.find(
            ({ phone }) => phone === this.signataire_phone
        );
      }
      if (chosen) {
        this.signataire = chosen.name;
      } else {
      }
    },

    checkSignatairesData() {
      if (this.signataire) {
        let chosen = this.signataires.find(
            ({ text }) => text === this.signataire
        );
        if (chosen) {
          this.signataire_phone = chosen.phone;
          this.signataire = chosen.name;
          let item = this.vehicule;
          /*  if (this.Isimportateur===0 ){
              this.newvoice=this.factureTypes[1].value;
            }else{
              this.newvoice=this.factureTypes[0].value;
            }*/
          return (this.venteForm = {
            id: item.id,
            manifeste: item.manifeste,
            prix_vente: item.prix_vente,
            prix_revient: item.prix_revient,
            societe: item.societe,
            marque: item.marque,
            numero_chassis: item.numero_chassis,
            annee: item.annee,
            couleur: item.couleur,
            traitement_special: "0",
            venteType: "Transit",
            invoice_type: this.newvoice,
            client: this.venteForm.client,
            client_reccm: this.venteForm.reccm,
            client_ifu: this.venteForm.ifu,
            client_phone: this.venteForm.phone,
            signataire: this.venteForm.signataire,
            signataire_phone: this.venteForm.signataire_phone,
          });

        }
      } else {
        this.signataire_phone = "";
      }
    },

    checkSignatairesDataProforma() {
      if (this.proformaForm.signataire) {
        let chosen = this.signataires.find(
            ({ id }) => id === this.proformaForm.signataire
        );
        if (chosen) {
          let item = this.vehicule;
          return (this.proformaForm = {
            id: item.id,
            manifeste: item.manifeste,
            prix_vente: item.prix_vente,
            prix_revient: item.prix_revient,
            societe: item.societe,
            marque: item.marque,
            numero_chassis: item.numero_chassis,
            annee: item.annee,
            couleur: item.couleur,

            client: this.proformaForm.client,
            client_reccm: this.proformaForm.reccm,
            client_ifu: this.proformaForm.ifu,
            client_phone: this.proformaForm.phone,
            signataire_phone: this.proformaForm.phone,
            signataire: this.proformaForm.signataire,
          });
        }
      } else {
        this.proformaForm.signataire_phone = "";
      }
    },

    saveDemarcheur(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;

      this.demarcheurForm.prix = this.formatAmount(this.demarcheurForm.prix);
      this.demarcheurForm.type = "Paiement démarcheur";
      this.demarcheurForm.id = this.vehicule.id;
      this.demarcheurForm.dev_conversion = this.vehicule.dev_conversion;

      this.etatButtonDemarcheur = false;

      this.$http
          .post("/maintenances", this.demarcheurForm)
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.etatButtonDemarcheur = true;
              this.showToast("Paiement enregistré avec succès", "success");
              this.vehiculeData();
              this.resetDemarcheur();
            } else {
              this.etatButtonDemarcheur = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonDemarcheur = true;
            this.isOperating = false;
            var err = e.response.data.message;
            this.showToast(err, "danger");
          });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    savePerte(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;

      this.perteForm.prix = this.formatAmount(this.perteForm.prix);
      if (this.perteForm.prix <= 0){
          this.showToast("Veuillez entré le montant de perte", "danger");
      }else{
          this.perteForm.type = "Perte de valeur";
          this.perteForm.id = this.vehicule.id;
          this.perteForm.dev_conversion = this.vehicule.dev_conversion;
          this.etatButtonPerte = false;
          this.$http
              .post("/maintenances", this.perteForm)
              .then((result) => {
                  this.isOperating = false;
                  if (result.data.success) {
                      this.etatButtonPerte = true;
                      this.showToast("Perte enregistré avec succès", "success");
                      this.vehiculeData();
                      this.resetPerte();
                  } else {
                      this.etatButtonPerte = true;
                      this.showToast(result.data.message, "danger");
                  }
              })
              .catch((e) => {
                  this.etatButtonPerte = true;
                  this.isOperating = false;
                  var err = e.response.data.message;
                  this.showToast(err, "danger");
              });

          // Hide the modal manually
          this.$nextTick(() => {
              this.$bvModal.hide("modal-prevent-closing");
          });
      }
    },

    checkDemarcheurData() {
      // console.log(this.demarcheurForm.beneficiaire)
      if (this.demarcheurForm.beneficiaire) {
        let dem = this.demarcheurs.find(
            (_item) => _item.name == this.demarcheurForm.beneficiaire
        );
        if (dem) {
          this.demarcheurForm.beneficiaire_phone = dem.phone;
        } else {
          this.demarcheurForm.beneficiaire_phone = "";
        }
        // let item = this.vehicule;
        // return (this.demarcheurForm = {
        //   id: item.id,
        //   dev_conversion: item.dev_conversion,
        //   beneficiaire_phone: dem.phone,
        //   beneficiaire: dem.id,
        // });
      } else {
        this.demarcheurForm.beneficiaire_phone = "";
      }
    },

    checkDemarcheurDataPhone() {
      if (this.demarcheurForm.beneficiaire_phone) {
        let chosen = this.demarcheurs.find(
            ({ phone }) => phone === this.demarcheurForm.beneficiaire_phone
        );
        if (chosen) {
          this.demarcheurForm.beneficiaire = chosen.name;
        }
      }
    },

    async getDemarcheurs() {
      await this.$http.get("/demarcheurs").then((response) => {
        this.demarcheurs = response.data.data;

        this.demarcheurs.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    async getSociete() {
      await this.$http.get("/societes").then((response) => {
        this.societeObjet = response.data.data[0];
        this.Isimportateur= this.societeObjet.importateur

      });
    },
    async getClients() {
      await this.$http.get("/clients").then((response) => {
        this.clients = response.data.data.reverse();
        /*    let newcli=this.clients[0];
            this.client=newcli.name
            this.client_phone=newcli.phone;*/
        this.clients.find(({ text }) => text === this.client)
        this.clients.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    async getSignataires() {
      await this.$http.get("/signataires").then((response) => {
        this.signataires = response.data.data.reverse();
        if (this.signataires != null && this.signataires.length===1){
          let newsig=this.signataires[0];
          this.signataire=newsig.name
          this.signataire_phone=newsig.phone
        }
        this.signataires.find(({ text }) => text === this.signataires)
        this.signataires.forEach((element) => {
          element.text = element.name;
        });

      });
    },

    validationFormInvoice() {
      return new Promise((resolve, reject) => {
        this.$refs.invoiceRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormClient() {
      return new Promise((resolve, reject) => {
        this.$refs.clientRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormDeposant() {
      return new Promise((resolve, reject) => {
        this.$refs.deposantRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormSignataire() {
      return new Promise((resolve, reject) => {
        this.$refs.signataireRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.detailsRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    resetCaution() {
      this.dialogCaution = false;
      this.cautionForm = {};
    },
    resetCarte() {
      this.dialogCarte = false;
      // this.carteForm = {};
    },
    resetDetails() {
      this.dialogDetails = false;
      this.detailsForm = {};
    },
    resetDemarcheur() {
      this.dialogDemarcheur = false;
      this.demarcheurForm = {};
    },
      resetPerte() {
      this.dialogPerte = false;
      this.perteForm = {};
    },
    resetClient() {
      this.dialogClient = false;
      this.clientForm = {};
    },
    resetUpdate() {
      this.dialogUpdate = false;
      this.updateForm = {};
    },
    resetDepense() {
      this.dialogDepense = false;
      this.depenseForm = {};
    },
    resetVente() {
      this.dialogVente = false;
      this.venteForm = {};
    },
    resetClientFields() {
      this.client_reccm = null;
      this.client_ifu = null;
      this.client_phone = null;
      this.client = null;
    },
    resetRecouvrir() {
      this.dialogRecouvrir = false;
      this.recouvrirForm = {};
    },
    resetProforma() {
      this.dialogProforma = false;
      this.proformaForm = {};
    },
    resetSortie() {
      this.dialogSortie = false;
      this.sortieForm = {};
    },

    saveDepense(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.isOperating = true;
      this.depenseForm.prix = this.$thousandFormater(this.depenseForm.prix);

      this.etatButtonDepense = false;

      this.$http
          .post("/maintenances", this.depenseForm)
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.etatButtonDepense = true;
              this.showToast("Dépense enregistrée avec succès", "success");
              this.vehiculeData();
              this.resetDepense();
            } else {
              this.etatButtonDepense = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonDepense = true;
            this.isOperating = false;
            var err = e.response.data.message;
            this.showToast(err, "danger");
          });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    saveRecouvrement(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;
      let mcf_request_content = [];
      let uniqueArr = this.vehicule.facture.filter(function (element, index) {
        return (
            element.invoice_type === "Facture normalisée" && element.archive == 0 && element.new === 0
        );
      });
      let hasStandardizedInvoice = uniqueArr.length;
      if (hasStandardizedInvoice) {
        this.generateData(this.recouvrirForm, "recouvrement");
      } else {
        this.recouvrirForm.prix_vente = this.$thousandFormater(
            this.recouvrirForm.montant_paye
        );
        this.saveSold("recouvrement");
      }
    },

    saveVente(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.client.name){
        this.client = this.client.name.toUpperCase();
      }else{
        this.client = this.client.toUpperCase();
      }
      this.signataire = this.signataire.toUpperCase();
      this.isOperating = true;
      if (this.venteForm.invoice_type == "Facture normalisée") {
        this.generateData(this.venteForm, "vente");
      } else {
        this.saveSold("vente");
      }
    },

    saveDetails(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.detailsForm.prix_vente = this.formatAmount(
          this.detailsForm.prix_vente
      );
      this.isOperating = true;

      this.etatButtonDetails = false;

      this.$http
          .put("/vehicules/" + this.detailsForm.id, this.detailsForm)
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.etatButtonDetails = true;
              this.showToast("Modification enregistrée avec succès", "success");
              this.vehiculeData();
              this.resetDetails();
            } else {
              this.etatButtonDetails = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonDetails = true;
            this.isOperating = false;
            console.log("erreur modification:", e.response.data);
            var err = e.response.data.message;
            this.showToast(err, "danger");
          });
    },

    saveSold(operation) {
      if (operation == "vente") {
        this.etatButtonSell = false;
        this.venteForm.montant_paye = this.formatAmount(this.montant_paye);
        this.venteForm.prix_de_vente = this.$thousandFormater(
            this.venteForm.prix_vente
        );
        this.venteForm.prix_vente = this.$thousandFormater(
            this.venteForm.prix_vente
        );
        this.venteForm.net_a_paye = this.formatAmount(this.netapayer);
        this.venteForm.reste_a_paye = this.formatAmount(this.rap);

        this.venteForm.client = this.client;
        this.venteForm.client_reccm = this.client_reccm;
        this.venteForm.client_ifu = this.client_ifu;
        this.venteForm.client_phone = this.client_phone;
        this.venteForm.signataire = this.signataire;
        this.venteForm.signataire_phone = this.signataire_phone;
        if(this.Isimportateur===0){
          if (this.venteForm.venteType==="Local"){
            this.venteForm.traitement_special=this.traiementTypes[1].value;
          }else if(this.venteForm.venteType==="Transit"){
            this.venteForm.traitement_special=this.traiementTypes[0].value;
          }
        }
      } else {
        this.etatButtonRecouvrement = false;
        this.recouvrirForm.montant_paye = this.formatAmount(
            this.recouvrirForm.montant_paye
        );
      }
      this.$http
          .post(
              "/factures",
              operation == "vente" ? this.venteForm : this.recouvrirForm
          )
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              if (operation == "recouvrement") {
                this.etatButtonRecouvrement = true;
              }

              if (operation == "vente") {
                this.etatButtonSell = true;
              }

              this.showToast("Vente enregistrée avec succès", "success");
              let id =
                  result.data.data.facture[result.data.data.facture.length - 1].id;
              let routeData = this.$router.resolve({
                name: "facture-show",
                params: { id: id },
              });
              window.open(routeData.href, "_blank");
              this.vehiculeData();
              this.resetVente();
              this.resetClientFields();
              this.resetRecouvrir();
            } else {
              if (operation == "recouvrement") {
                this.etatButtonRecouvrement = true;
              }

              if (operation == "vente") {
                this.etatButtonSell = true;
              }

              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            var err = e.response.data.message;

            if (operation == "recouvrement") {
              this.etatButtonRecouvrement = true;
            }

            if (operation == "vente") {
              this.etatButtonSell = true;
            }

            this.isOperating = false;

            this.showToast(err, "danger");
          });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    goto(item) {
      window.sessionStorage.setItem(
          "manifeste_data",
          JSON.stringify(item.manifeste)
      );
      this.$router.push({
        name: "manifesteDetails",
      });
    },
    gotoa(item) {
      this.$router.push({
        name: "associations-show",
        params: {
          id: item,
        },
      });
    },

    newCarte() {
      this.carteForm = this.vehicule;
      this.carteForm.montant = this.vehicule.last_prix_impression_carte_imp;
      this.carteForm.bl = this.vehicule.last_bl_impression_carte_imp;
      this.dialogCarte = true;
    },

    //CHECK IF IMAGE IS LOADED
    isLoaded(image) {
      // carImg
    },

    updatePrice() {
      this.updateForm.numero_chassis = this.vehicule.numero_chassis;
      this.updateForm.prix_carte_imp = this.vehicule.prix_carte_imp;
      this.updateForm.prix_transport = this.vehicule.prix_transport;
      this.updateForm.frais = this.vehicule.frais;
      this.updateForm.prix_achat = this.vehicule.prix_achat;

      this.updateForm.dev_achat = this.vehicule.dev_achat;
      this.updateForm.dev_carte_imp = this.vehicule.dev_carte_imp;
      this.updateForm.dev_frais = this.vehicule.dev_frais;
      this.updateForm.dev_transport = this.vehicule.dev_transport;

      this.dialogUpdate = true;
    },

    checkPriceChange() {
      if (
          this.vehicule.prix_carte_imp != this.updateForm.prix_carte_imp ||
          this.vehicule.prix_transport != this.updateForm.prix_transport ||
          this.vehicule.frais != this.updateForm.frais ||
          this.vehicule.prix_achat != this.updateForm.prix_achat
      ) {
        this.noChange = false;
      }
      return true;
    },

    formatAmounts(amounts) {
      amounts = JSON.parse(JSON.stringify(amounts));
      amounts.forEach((element) => {
        if (element.ci) {
          element.ci = this.$thousandFormater(element.ci);
        }
        if (element.montant_chargeur) {
          element.montant_chargeur = this.$thousandFormater(
              element.montant_chargeur
          );
        }
        if (element.frais) {
          element.frais = this.$thousandFormater(element.frais);
        }
      });
      return amounts;
    },
    formatAmount(amount) {
      amount = this.$thousandFormater(amount);
      return amount;
    },

    saveUpdate(bvModalEvt) {
      //Formatage des montants
      this.updateForm.prix_carte_imp = this.formatAmount(
          this.updateForm.prix_carte_imp
      );
      this.updateForm.prix_transport = this.formatAmount(
          this.updateForm.prix_transport
      );
      this.updateForm.frais = this.formatAmount(this.updateForm.frais);
      this.updateForm.prix_achat = this.formatAmount(
          this.updateForm.prix_achat
      );
      bvModalEvt.preventDefault();
      this.isOperating = true;
      this.etatButtonModifierPrix = false;

      this.$http
          .put("/review-vehicule/" + this.vehicule.id, this.updateForm)
          .then((response) => {
            this.isOperating = false;
            if (response.data.success) {
              this.etatButtonModifierPrix = true;
              this.showToast("Mise à jour effectuée avec succès", "success");
              this.resetUpdate();
              this.vehiculeData();
            } else {
              this.etatButtonModifierPrix = true;
              this.showToast(response.data.message, "danger");
            }
          })
          .catch((err) => {
            this.etatButtonModifierPrix = true;
            this.isOperating = false;
            this.showToast("L'enregistrement n'a pas aboutit.", "danger");
          });
    },

    async printCard() {
      this.isOperating = true;
      this.toPrint.montant = parse(
          this.toPrint.montant ? this.toPrint.montant : "0",
          this.options
      ).toString();
      await this.$http
          .post("/imprimer-carte-imp-vehicule", this.toPrint)
          .then((response) => {
            this.isOperating = false;
            this.openCard();
          })
          .catch((err) => {
            this.isOperating = false;
          });
    },

    openCard() {
      let routeData = this.$router.resolve({
        name: "importation-print-carte",
        query: {...this.toPrint, ifu: this.societeObjet.ifu, name: this.societeObjet.name, tel: this.societeObjet.telephone},
      });
      window.open(routeData.href, "_blank");
      this.dialogPrint = false;
      this.vehiculeData();
    },

    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
          "focus",
          () => {
            this.isSelecting = false;
          },
          { once: true }
      );

      this.$refs.uploader.click();
    },

    selectFiles(files) {
      this.selectedFile = files;
    },

    removeImage(image) {
      this.progress = true;
      const index = this.gallerie.indexOf(image);
      this.$http
          .put("/gallery/" + image.id, { link: image })
          .then((result) => {
            this.gallerie.splice(index, 1);
            this.vehiculeData();
            this.showToast("Suppression effectuée", "success");
          })
          .catch((err) => {
            console.log(err);
          });
    },
    // For scrolling zone
    onScroll() {
      this.scrollInvoked++;
    },

    // get the vehicule datas
    async vehiculeData() {
      let id = this.$router.currentRoute.params.id;
      await this.$http.get("/vehicules/" + id).then((response) => {
        this.vehicule = response.data.data;
        console.log("Vehicules",this.vehicule)
        this.depenses = this.vehicule.maintenances;
        this.gallerie = this.vehicule.gallery;
        if (this.Isimportateur===0){
          this.venteForm.invoice_type=this.factureTypes[1].value;
        }
        // Fetch only invoices which are not for ARCHIVE
        this.factures = [];
        this.vehicule.facture
            .slice()
            .reverse()
            .forEach((element) => {
              if (!element.archive) {
                this.factures.push(element);
                console.log(this.factures)
              }
            });

        this.factures = this.vehicule.facture ? this.vehicule.facture : [];
        if (this.factures.length > 0) {
          this.$http.get("/factures/" + this.factures[0].id).then((reponse) => {
            this.voitureClient = reponse.data.data.client;
          });
        }
      });
    },

    exportDepenses() {
      let { json2excel, excel2json } = require("js2excel");
      let data = [];
      this.depenses.forEach((element) => {
        let obj = {
          DATE: element.created_at,
          DESCRIPTION: element.description,
          MONTANT: element.prix,
          // TYPE: element.type,
          // DEVISE: element.conv_prix,
          // BENEFICIAIRE: element.beneficiaire,
        };
        data.push(obj);
      });
      if (data.length) {
        try {
          json2excel({
            data,
            name: "FlashCar - Liste des Dépenses",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.error("export error");
        }
      } else {
        this.showToast("Ce tableau ne contient aucun élement", "danger");
      }
    },

    exportData() {
      let { json2excel, excel2json } = require("js2excel");
      let data = [];
      this.factures.forEach((element) => {
        let obj = {
          "NUMERO(S)": element.facture_no,
          ASSOCIATION: element.association_importateur
              ? element.association_importateur
              : "------",
          "MONTANT VENTE": element.montant_vente,
          "MONTANT PAYE": element.montant_paye,
          "TOTAL PAYE": element.total_paye,
          "RESTE A PAYER": element.reste_a_paye,
          "TYPE DE FACTURE": element.invoice_type,
          SIGNATAIRE: this.signataires.find(
              (_cli) => _cli.id == element.signataire_id
          ).name,
          DATE: element.created_at,
        };
        data.push(obj);
      });
      if (data.length) {
        try {
          json2excel({
            data,
            name: "FlashCar - Liste des Factures",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        this.showToast("Ce tableau ne contient aucun élement", "danger");
      }
    },

    optionDepense() {
      if (
          this.vehicule.statut == "en stock" ||
          this.vehicule.statut == "en dépôt" ||
          this.vehicule.statut == "en cours de vente" ||
          this.vehicule.statut == "vendu"
      ) {
        return true;
      } else return false;
    },

    optionModifier() {
      if (
          this.vehicule.statut == "en stock" ||
          this.vehicule.statut == "en dépôt"
      ) {
        return true;
      } else return false;
    },

    optionVendre() {
      if (
          this.vehicule.statut == "en stock" ||
          this.vehicule.statut == "en dépôt"
      ) {
        return true;
      } else return false;
    },

    optionGallerie() {
      if (this.vehicule.statut != "livré") {
        return true;
      } else return false;
    },

    optionSortir() {
      if (this.vehicule.statut == "vendu") {
        return true;
      } else return false;
    },

    optionFacture() {
      if (
          this.vehicule.statut == "vendu" ||
          this.vehicule.statut == "en cours de vente"
      ) {
        return true;
      } else return false;
    },

    optionDemarcheur() {
      if (
          this.vehicule.statut !== "en stock" &&
          this.vehicule.statut !== "en dépôt" &&
          this.vehicule.statut != "livré" &&
          this.vehicule.statut != "en attente"
      ) {
        return true;
      } else return false;
    },

    optionCaution() {
      if (
          this.vehicule.statut == "en cours de vente" ||
          this.vehicule.statut == "vendu"
      ) {
        return true;
      } else return false;
    },

    optionPrintCI() {
      if (
          this.societeObjet &&
          this.societeObjet.importateur == 1 &&
          (this.vehicule.statut == "en cours de vente" ||
              this.vehicule.statut == "vendu")
      ) {
        return true;
      } else return false;
    },

    optionRecouvrement() {
      if (this.vehicule.statut == "en cours de vente") {
        return true;
      } else return false;
    },

    optionAnnulerVente() {
      if (
          this.vehicule.statut == "en cours de vente" ||
          this.vehicule.statut == "vendu"
      ) {
        return true;
      } else return false;
    },

    newDepense() {
      let item = this.vehicule;
      let toForm = {
        id: item.id,
        observation: "",
        type: "Autre",
        dev_conversion: item.dev_conversion,
        prix: 0,
      };

      this.depenseForm = toForm;
      this.dialogDepense = true;
    },

    newCaution() {
      let item = this.vehicule;
      let toForm = {
        id: item.id,
        caution: item.detail_vente.caution,
      };

      this.cautionForm = toForm;
      this.dialogCaution = true;
    },

    editItem() {
      let item = this.vehicule;

      let toForm = {
        id: item.id,
        prix_vente: this.$thousandFormater(item.prix_vente),
        prix_revient: item.prix_revient,
        carburant: item.carburant,
        nbr_portes: item.nbr_portes,
        boite_vitesse: item.boite_vitesse,
        puissance_fiscale: item.puissance_fiscale,
        kilometrage: item.kilometrage,
        caracteristiques: item.caracteristiques,

        couleur: item.couleur,
        marque: item.marque,
        annee: item.annee,
        numero_chassis: item.numero_chassis,
      };

      this.detailsForm = toForm;
      this.dialogDetails = true;
    },

    sellItem() {
      let item = this.vehicule;
      if (this.Isimportateur===0 ){
        this.newvoice=this.factureTypes[1].value;
      }else{
        this.newvoice=this.factureTypes[0].value;
      }
      let toForm = {
        id: item.id,
        manifeste: item.manifeste,
        prix_vente: this.$thousandFormater(item.prix_vente),
        prix_revient: item.prix_revient,
        societe: item.societe,
        marque: item.marque,
        numero_chassis: item.numero_chassis,
        annee: item.annee,
        couleur: item.couleur,
        traitement_special:"0",
        venteType: "Transit",
        invoice_type: this.newvoice,
        signataire: this.signataire,
        signataire_phone: this.signataire_phone,
      };
      this.venteForm = toForm;
      this.dialogVente = true;
    },

    newProforma() {
      let item = this.vehicule;
      let toForm = {
        id: item.id,
        manifeste: item.manifeste,
        prix_vente: this.$thousandFormater(item.prix_vente),
        prix_revient: item.prix_revient,
        societe: item.societe,
        marque: item.marque,
        numero_chassis: item.numero_chassis,
        annee: item.annee,
        couleur: item.couleur,
      };
      console.log(toForm)

      this.proformaForm = toForm;
      this.dialogProforma = true;
    },

    paidDemarcheur() {
      let item = this.vehicule;
      let toForm = {
        id: item.id,
        prix: 0,
        dev_conversion: item.dev_conversion,
      };

      this.demarcheurForm = toForm;
      this.dialogDemarcheur = true;
    },
      perte() {
      let item = this.vehicule;
      let toForm = {
        id: item.id,
        prix: 0,
        dev_conversion: item.dev_conversion,
      };

      this.perteForm = toForm;
      this.dialogPerte = true;
    },

    recouvrirVente() {
      this.recouvrirForm =
          this.vehicule.facture[this.vehicule.facture.length - 1];
      this.recouvrirForm.montant_paye = 0;
      this.recouvrirForm.id = this.recouvrirForm.vehicule_id;
      this.recouvrirForm.client = this.recouvrirForm.client_id;
      this.recouvrirForm.signataire = this.recouvrirForm.signataire_id;
      this.dialogRecouvrir = true;
    },

    editClient() {
      this.clientForm = {
        id: this.vehicule.id,
        client: this.vehicule.facture[0].client_id,
        oldClient: this.vehicule.facture[0].client_id,
      };
      this.dialogClient = true;
    },

    sortieVehicule() {
      let item = this.vehicule;
      let toForm = {
        id: item.id,
      };

      this.sortieForm = toForm;
      this.dialogSortie = true;
    },

    annulationVente() {
      let item = this.vehicule;
      let toForm = {
        id: item.id,
        prix_vente: this.$thousandFormater(item.prix_vente),
      };
      this.annulationForm = toForm;
      this.$bvModal
          .msgBoxConfirm(
              "Vous êtes sur le point d'annuler la vente de ce véhicule.",
              {
                title: "Annulation vente",
                okVariant: "success",
                cancelVariant: "outline-danger",
                okTitle: "Confirmer",
                cancelTitle: "Annuler",
                hideHeaderClose: false,
                centered: true,
              }
          )
          .then((value) => {
            if (value) {
              this.cancelSold();
            }
          });
    },

    cancelSold() {
      let mcf_request_content = [];
      let uniqueArr = this.vehicule.facture.filter(function (element, index) {
        return (
            element.invoice_type === "Facture normalisée" && element.archive === 0 && element.new === 0
        );
      });
      let hasStandardizedInvoice = uniqueArr.length;
      let today = new Date();
      var dateToday =
          today.getDate() +
          "/" +
          (today.getMonth() + 1) +
          "/" +
          today.getFullYear();
      if (hasStandardizedInvoice) {
        for (var i=0; i<uniqueArr.length; i++) {
          const element = uniqueArr[i];
          let mcf_response = JSON.parse(element["mcf_response"]);

          // if (typeof mcf_response !== 'object') {
          //   this.showToast('Saved response: ' + mcf_response, 'danger');
          //   return;
          // }

          let obj = {
            type: "FA",
            original: mcf_response["nim"] + "-" + mcf_response["tc"],
            reference: "--------",
            date: dateToday,
            discount: 0,
            company: {
              name: mcf_response["companyName"],
              address: mcf_response["companyAdress"],
              contact: mcf_response["companyContact"],
            },
            operator: {
              id: mcf_response["operatorId"],
              name: mcf_response["operatorName"],
            },
            customer: {
              name: mcf_response["customerName"],
              ifu: mcf_response["customerIfu"],
              amount: mcf_response["customerAmount"],
              change: mcf_response["customerChange"],
            },
            vat: {
              type: mcf_response["items"][0]["vatType"],
              value: mcf_response["items"][0]["vat"],
            },
            tax: mcf_response["items"][0]["specificTax"],
            aib: mcf_response["aib"],
            products: [
              {
                description: mcf_response["items"][0]["name"],
                price: mcf_response["items"][0]["unitPrice"],
                quantity: mcf_response["items"][0]["quantity"],
              },
            ],
          };
          mcf_request_content.push(obj);
        }

        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer bXNmOnVzZXI=");
        myHeaders.append("content-type", "application/json");
        myHeaders.append("content", JSON.stringify(mcf_request_content));

        var myInit = {
          method: "POST",
          headers: myHeaders,
          mode: "cors",
          cache: "default",
        };
        this.isOperating = true;
        fetch("http://127.0.0.1:3440", myInit)
            .then((res) => {
              return res.json();
            })
            .then((res) => {
              this.isOperating = false;
              if (res && !res.success) {
                this.standardizedInvoiceGenerated = false;
                if (res.result == "No device connected")
                  res.result = "Aucun appareil connecté";
                else if (res.result == "Device not compatible")
                  res.result = "Appareil MECEF non compatible";
                else res.result = "Veuillez lancer le serveur MECEF";
              } else if(res) {

                // if (typeof res.result !== 'object') {
                //   this.showToast('MECEF Response ' + res.result, 'danger');
                //   return;
                // }

                this.annulationForm.mcf_request_content = res.result;
                this.annulationForm.mcf_response = res.result;
                this.saveAnnulation();
                this.standardizedInvoiceGenerated = true;
              }

              // throw res;
            })
            .catch((error) => {
              // this.showToast(error, "danger");
              this.isOperating = false;
              this.standardizedInvoiceGenerated = false;
              this.showToast("Impossible de joindre le serveur MECEF", "danger");
            });
      } else {
        this.saveAnnulation();
        this.annulationForm.invoice_type = "Facture simple";
        let type  =this.vehicule.facture.slice(-1);
        this.annulationForm.type=type[0].traitement_special

      }
    },

    saveAnnulation() {
      console.log(this.annulationForm)
      this.$http
          .post("/annuler-vente", this.annulationForm)
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.showToast("Annulation enregistrée avec succès", "success");
              this.vehiculeData();
            } else {
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.isOperating = false;
            var err = e.response.data.message;
            this.showToast(err, "danger");
          });
    },

    parsing(toParse) {
      // return parse(toParse, this.options).toString()
      return parse(toParse ? toParse.toString() : "0", this.options);
    },

    generateData(vehicule, typeOperation) {
      let today = new Date();
      let data = "";
      // Other
      var date =
          today.getDate() +
          "/" +
          (today.getMonth() + 1) +
          "/" +
          today.getFullYear();

      switch (typeOperation) {
        case "vente":
          // Get type of invoice
          let clientObject = this.clients.find(
              (_cli) => _cli.text.toUpperCase() == this.client
          );

          if (!clientObject) {
            clientObject = {};
            clientObject.name = this.client;
          }
          let newifu
          if(clientObject.ifu===null){
            newifu="0000000000000"
          }else if(clientObject.ifu.length< 13){
            newifu="0000000000000"
          }
          else {
            newifu=clientObject.ifu
          }
          data = {
            type: vehicule.venteType == "Transit" ? "EV" : "FV",
            orignal: "000",
            date: date,
            reference: "-------",
            discount: 0,
            company: {
              name: this.societeObjet.name,
              address: this.societeObjet.address,
              contact: this.societeObjet.telephone,
              ifu: this.societeObjet.ifu,
            },
            operator: {
              id: this.user.id,
              name: this.user.name,
            },
            customer: {
              name: clientObject.name,
              ifu:newifu,
              amount: this.$thousandFormater(vehicule.montant_paye),
              change: 0,
            },
            tax: 0,
            vat: {
              type: "A",
              value: 0,
            },
            aib: "",
            products: [
              {
                description: vehicule.marque + "-" + vehicule.numero_chassis,
                price: this.$thousandFormater(vehicule.montant_paye),
                quantity: 1,
              },
            ],
          };

          break;
        case "recouvrement":
          // this.recouvrirForm.prix_vente = this.recouvrirForm.montant_paye;
          let lastInvoice = JSON.parse(vehicule.mcf_response);
          let  lastifu
          if(lastInvoice.customerIfu===null){
            lastifu="0000000000000"
          }else if(lastInvoice.customerIfu.length< 13){
            lastifu="0000000000000"
          }
          else {
            lastifu=lastInvoice.customerIfu
          }
          data = {
            type: lastInvoice.type,
            orignal: "000",
            date: date,
            reference: "-------",
            discount: 0,
            company: {
              name: lastInvoice.companyName,
              address: lastInvoice.companyAdress,
              contact: lastInvoice.companyContact,
              ifu: lastInvoice.ifu,
            },
            operator: {
              id: lastInvoice.operatorId,
              name: lastInvoice.operatorName,
            },
            customer: {
              name: lastInvoice.customerName,
              ifu: lastifu,
              amount: this.$thousandFormater(this.recouvrirForm.montant_paye),
              change: 0,
            },
            tax: 0,
            aib: "",
            vat: {
              type: "A",
              value: 0,
            },
            products: [
              {
                description: lastInvoice.items[0].name,
                price: this.$thousandFormater(this.recouvrirForm.montant_paye),
                quantity: 1,
              },
            ],
          };
          break;

        default:
          break;
      }

      this.isOperating = true;
      var myHeaders = new Headers();
      myHeaders.append("authorization", "Bearer bXNmOnVzZXI=");
      myHeaders.append("content-type", "application/json");
      myHeaders.append("content", JSON.stringify(data));

      var myInit = {
        method: "POST",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      };

      if (typeOperation == "vente") {
        this.etatButtonSell = false;
      }

      if (typeOperation == "recouvrement") {
        this.etatButtonRecouvrement = false;
      }

      fetch("http://127.0.0.1:3440", myInit)
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            this.isOperating = false;
            if (res && !res.success) {
              if (typeOperation == "vente") {
                this.etatButtonSell = true;
              }

              if (typeOperation == "recouvrement") {
                this.etatButtonRecouvrement = true;
              }

              this.standardizedInvoiceGenerated = false;
              let message = 'Impossible de joindre le serveur MECEF'
              if (res.result == "No device connected")
                message = "Aucun appareil connecté";
              else if (res.result == "Device not compatible")
                message = "Appareil MECEF non compatible";
              else message = "Impossible de traiter la demande";
              this.showToast(message, "danger");
            } else if(res) {
              this.standardizedInvoiceGenerated = true;
              if (typeOperation == "vente") {
                this.venteForm.mcf_request_content = res.result;
                this.venteForm.mcf_response = res.result;
                this.saveSold("vente");
              } else if (typeOperation == "recouvrement") {
                this.recouvrirForm.mcf_request_content = res.result;
                this.recouvrirForm.mcf_response = res.result;
                this.saveSold("recouvrement");
              }
            }

            // throw res;
          })
          .catch((error) => {
            this.isOperating = false;
            this.standardizedInvoiceGenerated = false;

            if (typeOperation == "vente") {
              this.etatButtonSell = true;
            }

            if (typeOperation == "recouvrement") {
              this.etatButtonRecouvrement = true;
            }

            // this.showToast(error.result, "danger");
          });
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";


.datalist-style {

  display: none;
  height: 80vh;
  overflow: auto;
  left: 0;
  border: 1px solid black;
}
.datalist-container {

}
.datalist-style option {
  font-family: arial;
}
.e-input-group {
  border-color: rgb(0 0 0 / 15%) !important;
  border-width: 1px !important;
  border-radius: 5px !important;
  color: rgb(127 125 139) !important;
  line-height: 2.1 !important;
  padding-left: 15px !important;
}


.b-form-date-controls {
  margin-right: 38%;
}

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}
.iconFeather:hover {
  cursor: pointer;
}

.img-carousel img {
  height: 320px;
}

.img-fluid {
  max-width: 100% !important;
}

.card-img-top {
  height: 100px;
}
.custom-file-label::after {
  content: "" !important;
  border-left: white !important;
}

#bTable1, #bTable2 {
  position: relative !important;
}

@media screen and (max-width: 500px) {
  #content-imgDetails {
    display: block !important;
  }

  #content-img {
    position: relative !important;
    max-width: 100% !important;
    margin-bottom: 35px;
  }

  #content-details {
    position: relative !important;
    max-width: 100% !important;
  }

}

@media screen and (max-width: 435px) {
  .container-button, .container-export-details-vehicule {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
</style>
